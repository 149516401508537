export default {
  namespaced:true,
  state:() =>({
    
    contenido:{
      id:null,
      archivo_id:null,
      widget_id:null,
      ticker_id:null,
      fecha_inicio:new Date(),
      fecha_fin:null,
      publicado:false,
      orden:0,
      solicitud_id:null,
      programacion_id:null,

      archivo:null,
      widget:null,
      ticker:{
        id:null,
        ticker:''
      },
      solicitud:null,
      duracion:0,

    },
    contenidos:[],
    descripcion:''
  }),

  getters:{
    draft:(state) => clone(state.contenido),
  },



  mutations:{

    clear(state){
      state.contenido = {
        id:null,
        archivo_id:null,
        widget_id:null,
        ticker_id:null,
        fecha_inicio:new Date(),
        fecha_fin:null,
        publicado:false,
        orden:0,
        solicitud_id:null,
        programacion_id:null,

        archivo:null,
        widget:null,
        ticker:{
          id:null,
          ticker:''
        },
        solicitud:null,
        duracion:0,
        descripcion:''
      }
    },


    setContenidos(state,contenidos){
      if(contenidos.length){
        state.contenidos = contenidos
      }
    },


    setContenido(state,contenido){
      if( contenido){
        state.contenido = contenido
      }
    },

    update(state,contenido){
      let i = state.contenidos.findIndex(c => c.id == contenido.id)

      if(i != -1){
        state.contenidos[i] = contenido
      }

      if(state.contenido.id == contenido.id){
        state.contenido = contenido
      }
    },

    pushContenido:(state,contenido) => {
      if(typeof contenido !== 'object') return;
      state.contenidos.push({
        ...contenido,
        orden:state.contenidos.length ?  (state.contenidos.length - 1) + 1 : 0 
      })

    },

    reordenarContenidos:(state,{element,newIndex,oldIndex}) => {
      state.contenidos.forEach(contenido => {
        
        if(contenido.orden == newIndex){
          contenido.orden = oldIndex
        }

        if(contenido.orden == oldIndex){
          contenido.orden = newIndex
        }

      });
    },

    removerContenido(state,index){
      state.contenidos.splice(index,1)
    },

    quitarContenidos(state){
      state.contenidos = [];
    }


  },


  actions:{

    fetch({commit},contenido_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/contenidos/${contenido_id}/fetch-data`).then(({data}) => {
          commit('setContenido',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit,dispatch},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/contenidos/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    fetchDataContenidoPublicado({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/contenidos/fetch-data-publicado`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/contenidos/${datos.id}`,datos).then(({data}) => {
            if(data.result){
              commit('update',data.contenido)
            }
            resolve(data)
          }).catch(e => reject(e))
        }else{
          axios.post(`/api/contenidos`,datos).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
        }
         
      })
    },

    eliminar({state,commit},contenido_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/contenidos/${contenido_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    fetchContenidoPorSolicitud({commit},solicitud_id){
      
      return new Promise((resolve, reject) => {
          axios.get(`/api/contenidos/solicitud/${solicitud_id}`).then(({data}) => {
            commit('setContenidos',data.contenidos)
            resolve(data)
          }).catch(e => reject(e));

      });
    },

    fetchContenidoPorCanal({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/contenidos/canal`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e));
      });

    },

    fetchUrlReportContenidoCanal({commit},{canal_id,filtro}){
      return new Promise((resolve, reject) => {
        axios.put(`/api/contenidos/canal/${canal_id}/report/url-pdf`,{filtro}).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e));
      });
    }

  }

}