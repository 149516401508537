export default{
  namespaced:true,

  state:() => ({
    region:{
      id:null,
      color_fondo:'#283046',
      nombre:null,
      imagen:null,
      imagen_url:null,
      hash_imagen:null,
      tipo_contenido:1, // [1 => video , 2 => imagenes, 3 => widget, 4 => tickers]
      display_id:null,
      style: {
        width: 180,
        height: 100,
        left: 10,
        top: 10,
      }
    },
    regions:[],
    
    tiposAdmitidos:[
      {text:'Vídeo',value:1},
      {text:'Imagenes',value:2},
      {text:'Widget',value:3},
      {text:'Tickers',value:4},
    ],
    predefineColors:[
      '#ff4500',
      '#ff8c00',
      '#ffd700',
      '#90ee90',
      '#00ced1',
      '#1e90ff',
      '#c71585',
      'rgba(255, 69, 0, 0.68)',
      'rgb(255, 120, 0)',
      'hsv(51, 100, 98)',
      'hsva(120, 40, 94, 0.5)',
      'hsl(181, 100%, 37%)',
      'hsla(209, 100%, 56%, 0.73)',
      '#c7158577'
    ]

  }),

  getters:{
    draft:(state) => state.region,

    tipoContenidoListSelect:(state) => {
      return state.tiposAdmitidos
    },
    getTipoAdmitido(state){
      let tipo = state.tiposAdmitidos.find(v => v.value === state.region.tipo_contenido)
      if(tipo != undefined){
        return tipo.text
      }
      
      return 'Ticker'
    },

    regionPorNombre: (state) => (nombre_canal) => {
      let reg =  state.regions.find(v => v.nombre === nombre_canal)

      if(reg != undefined){
        return reg
      }

      return state.region;

    }
  },

  mutations:{

    clear(state){
      state.region = { id:null,
        color_fondo:'#283046',
        nombre:null,
        imagen:null,
        imagen_url:null,
        hash_imagen:null,
        tipo_contenido:1,
        display_id:null,
        style: {
          width: 180,
        height: 100,
          left: 10,
          top: 10,
        }
      }
    },

     clearRegions(state){
      state.regions = []
    },


    setRegion:(state,region) => state.region = region,
    setRegions:(state,regions) => state.regions = regions,
    updateRegion:(state,region) => {
      let r = state.regions.findIndex(v => v.id === region.id);
      if(r){
        state.regions[r] = region
        if(state.region.id === region.id){
          state.region = region
        }
      }

    },

    pushRegion(state,region){
      state.regions.push(region)
    },

    removeRegion(state,r_id) {
        let r = state.regions.findIndex(v => v.id === r_id)

        if(r != -1){
          state.regions.splice(r,1)
        }

    },

    addRegion : (state) => {
      state.regions.push({
        ...state.region,
        id:state.regions.length + 1,
        imagen_url:state.region.imagen ? URL.createObjectURL(state.region.imagen) : ''
      });
    },

    

    

  },

  actions:{

    fetch({state,commit},region_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/regions/${region_id}/fetch-data`).then(({data}) => {
          commit('setRegion',data)
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    fetchRegion({state,commit},region_id){

    },

    fetchRegions({commit,state},tv_display_id){

    },

    eliminar({commit,state},region_id){

    },

    addRegion({commit}){
      commit('addRegion')
      commit('clear')
    },


    fetchRegionsForDisplay({state,commit},display_id){
      
      return new Promise((resolve, reject) => {
        axios.get(`/api/regions/display/${display_id}`).then(({data}) => {
          commit('setRegions',data)
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    fetchRegionsPorCanal({state,commit},canal_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/regions/canal/${canal_id}`).then(({data}) => {
          commit('setRegions',data)
          resolve(data)
        }).catch(e => reject(e))
      })
    }

  }

}