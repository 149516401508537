export default{
  namespaced:true,
  state(){
    return{
      servicio:{
        id:null,
        nombre:null,
        descripcion:null,
        productos:[]

      },
      servicios:[],
    }
  },


  getters:{
    draft(state){
      return state.servicio
    }
  },

  mutations:{

    clear(state){
      state.servicio = {
         id:null,
        nombre:null,
        descripcion:null,
        productos:[]
      }
    },

    setServicio(state,servicio){
      if(servicio){
        state.servicio = servicio
      }
    },
    
    setServicios(state,servicios){
      state.servicios = servicios
    },

    put(state,servicio_id){

      if(state.servicios.length){
        state.servicios.splice(
          state.servicios.findIndex(i => i.id === servicio_id),
          1
        )
      }
    },

  },

  actions:{

    fetch({commit},servicio_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/servicios/${servicio_id}/fetch-data`).then(({data}) => {
          commit('setServicio',data)
          resolve(data) 
        }).catch(e  => reject(e))

      })
    },

    lightServicios({commit}){
      return new Promise((resolve, reject) => {
        axios.get(`/api/servicios/fetch-data-light`).then(({data}) => resolve(data)).catch(e => reject(e));
      })
    },

    
    fetchData({commit},filtro){
      return new Promise((resolve, reject) => {
        axios.post(`/api/servicios/fetch-data`,filtro).then(({data})  => {
          commit('setServicios',data.servicios)
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    eliminar({commit},servicio_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/servicios/${servicio_id}`).then(({data}) => {
          if(data.result){
            commit('put',servicio_id)
          }
          resolve(data)
        }).catch(e => reject(e))
      })
    },

   guardar({commit},datos){
    return new Promise((resolve, reject) => {
      if(datos.id){
        axios.put(`/api/servicios/${datos.id}`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      }else{
        axios.post(`/api/servicios`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      }
    })
   }
  }
}