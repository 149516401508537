
export default{
   namespaced:true,
   state(){
      return{
         cant_compras:0,
         canals:[],
         odts:{
            total:0,
            pendientes:0,
            rechazadas:0,
            aprobadas:0,
         },
         inversion_odt:0,
         odts_disponibles:0,
         solicitudes_generacion_contenido:0,
         inversion_gc:0,
         total_responsables_activos:0,
         responsables:[],
         saldo:{
            disponible:0,
            total_consumo_mensual:0,
            total_consumo_anual:0
         },

         // tablero admin
         totales:{
            clientes:0,
            canales:{
               total:0, // mostrar en color azul
               activos:0, // mostrar en color verde
               inactivos:0, // mostrar en color gris
               desconectados:0, // mostrar en color rojo
            },
            solicitudes:{
               recibidas:0,
               pendientes:0,
               aprobadas:0,
               rechazadas:0,
            }
         },

      }
   },

   mutations:{
      

      setCantCompras(state,cant_compras){
         state.cant_compras = cant_compras;
      },

      setCanals(state,canales){
         state.canals = canales;
      },

      setOdts(state,odts){
         state.odts = odts;
      },
      setInversionOdt(state,inversion){
         state.inversion_odt = inversion
      },
      setOdtsDisponibles(state,odts){
         state.odts_disponibles = odts
      },

      setSolicitudesGeneracionContenido(state,solicitudes){
         state.solicitudes_generacion_contenido = solicitudes;
      },

      setInversionGc(state,inversion){
         state.inversion_gc = inversion
      },

      setTotalResponsablesActivos(state,total){
         state.total_responsables_activos = total;
      },

      setResponsables(state,responsables){
         state.responsables = responsables;
      },
      setSaldo(state,saldo){
         state.saldo = saldo;
      },

      setTableroAdmin(state,tablero_admin){

         if(tablero_admin){
            const {
               clientes,
               canales,
               solicitudes
            } = tablero_admin;

            state.totales.clientes = clientes;
            state.totales.canales = canales;
            state.totales.solicitudes = solicitudes;
         }

      }


   },

   actions:{   


      cargarTablero({commit,state},{cliente_id,filtro}){

         return new Promise((resolve, reject) => {
            axios.post(`/api/dashboard/tablero`,{cliente_id,filtro})
            .then(({data}) => {
               const {canales,odts,inversion_odt :iodt,odts_disponibles: dodt} = data;
               commit('setCanals',canales);
               commit('setOdts',odts);
               commit('setInversionOdt',iodt);
               commit('setOdtsDisponibles',dodt);
               commit('setSolicitudesGeneracionContenido',data.solicitudes_generacion_contenido);
               commit('setInversionGc',data.inversion_gc);
               commit('setTotalResponsablesActivos',data.total_responsables_activos);
               commit('setResponsables',data.responsables);
               commit('setSaldo',data.saldo);
               resolve(data);
            }).catch(e => reject(e))

         })
      },


      cargarTableroAdmin({commit,state},filtro){
         return new Promise((resolve, reject) => {
            
            axios.post(`/api/dashboard/tablero-admin`,{filtro}).then(({data}) => {
               commit('setTableroAdmin',data);
               resolve(data);
            }).catch(e => reject(e));

         })
      },




      cargarCantCompras({commit}){
         return new Promise((resolve,reject)=>{
            axios.get(`/api/dashboard/data/tablero-admin`).then(({data}) => {
               commit('setCantCompras',data.cant_compras);
               resolve(data)
            }).catch(e => reject(e))
         });
      },

      cargarReporteOdts({commit},datos){
         return new Promise((resolve, reject) => {
            axios.post(`/api/reportes/odts`,datos).then(({data}) => resolve(data)).catch(e => reject(e));
         })
      }
     

   }


}
