import Vue from 'vue'

const crearContentElement = () => {
  let contentElement = document.createElement('div')
  contentElement.className  = 'content-loading';
  contentElement.style.display = 'none'

  contentElement.style.position  = 'absoluite';
  let elementLoading = document.createElement('div')
  elementLoading.className  = 'lds-roller';

  for (let index = 0; index < 8; index++) {
    elementLoading.appendChild(document.createElement('div'))
  }


  contentElement.appendChild(elementLoading)

  return contentElement;
}

Vue.directive('loading',{
  bind:function(el,{value}) {
    el.style.position = 'relative';
  },

  inserted:(el,{value}) => {
     const elm = crearContentElement();
    el.appendChild(elm);
    // if(!el.contains(elm)){
    //   el.appendChild(elm)
    // }
  },  



  update(el,{value}){
    const contentElement = el.querySelector('.content-loading'); // Busca el contentElement dentro del elemento

    if(value){
      contentElement.style.display = 'flex'
    }else{
      contentElement.style.display = 'none'
    }

  },

  unbind(el, binding) {
    const contentElement = el.querySelector('.content-loading'); // Busca el contentElement dentro del elemento
    // Si el valor del binding es false, elimina el elemento de carga
    if (!binding.value) {
      contentElement.style.display = 'none'
    }
  },
})