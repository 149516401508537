export default {
   namespaced:true,
   state:() => ({

      entrega:{
         id          : null,
         estatus     : 0, // 0 => pendiente, 1 => creando , 2 => creado , 3 => embalado , 4 => enviado , 5 => entregado
         observacion : '',
         traking     : '',
         compra_id   : null,
         producto_id   : null,
         producto:null,
         is_contenido: false,
         data_producto:null,
         fecha_envio:null,
         fecha_entrega:null,
         archivo:null,
         url_archivo:null
      },

      entregas:[]

   }),


   getters:{
      draft(state){
         return clone(state.entrega)
      },
      optionsEstatus:state => {
         return [
            {value:0,text:'Pendiente'},
            {value:1,text:'En Proceso'},
            {value:2,text:'Creado'},
            {value:3,text:'Embalado'},
            {value:4,text:'Enviado'},
            {value:5,text:'Entregado'}
         ]
      },

      entregaEsContenido:state => {
         return state.entrega.is_contenido
      }
   },

   mutations:{

      clear(state){
         state.entrega = {
           id          : null,
            estatus     : 0, // 0 => pendiente, 1 => creando , 2 => creado , 3 => embalado , 4 => enviado , 5 => entregado
            observacion : '',
            traking     : '',
            compra_id   : null,
            producto_id   : null,
            producto:null,
            is_contenido: false,
            data_producto:null,
            fecha_envio:null,
            fecha_entrega:null,
            archivo:null,
            url_archivo:null
         }
      },


      setEntregas(state,data){
         if(data.length){
            state.entregas = data
         }
      },

      setEntrega(state,entrega){
         state.entrega = entrega
      },

      push(state,entrega){
         
         if(entrega.id){
            state.entregas.push(entrega)
         }

      },

      put(state,entrega_id){
         
         state.entregas.splice(
            state.entregas.findIndex(val => val.id === entrega_id),
            1
         );
      },


      capturar(state,entrega_id){

         if(entrega_id && state.entregas.length){
            state.entrega = state.entregas.find(val => val.id === entrega_id)
         }

      },

      update(state,entrega){
         let i = state.entregas.findIndex(val => val.id === entrega.id)

         if(i != -1){  
            state.entregas[i] = entrega
            if(state.entrega.id === entrega.id){
               state.entrega = entrega               
            }
         }

      },

      updateEstatus(state,estatus){
         if(typeof estatus === 'object'){
            state.entrega.estatus = estatus.estatus
            state.entrega.details_estatus = estatus.estatus_details
         }else{
            state.entrega.estatus = estatus
         }
       
      },

      updateTraking(state,traking){
         state.entrega.traking = traking
      },
      updateArchivo(state,archivo){
         state.entrega.archivo = archivo
      },
      setUrlArchivo(state,url){
         state.entrega.url_archivo = url
      }

   },


   actions:{

      fetchData({commit},datos){
         return new Promise((resolve, reject) => {
            axios.post('/api/entregas/fetch-data',datos).then(({data}) => {
               commit('setEntregas',data.entregas);
               resolve(data);
            }).catch(e => reject(e))
         });

      },


      fetch({commit},entrega_id){

         return new Promise((resolve, reject) => {

            axios.get(`/api/entregas/${entrega_id}/fetch-data`).then(({data}) => {
               commit('setEntrega',data)
               resolve(data);

            }).catch(e => reject(e))

         })
      },

      guardar({state,commit},datos){

         return new Promise((resolve, reject) => {

            if (state.entrega.id) {

               axios.put(`/api/entregas/${datos.id}`, datos).then(({ data }) => {
                  commit('update', data.entrega)
                  resolve(data)
               }).catch(e => reject(e))


            } else {

               axios.post(`/api/entregas`, datos).then(({ data }) => {

                  commit('push', data.entrega)
                  resolve(data)

               }).catch(e => reject(e))

            }
         })
      },

      eliminar({commit},entrega_id){

         return new Promise((resolve, reject) => {
            axios.delete(`/api/entregas/${entrega_id}`).then(({data}) => {
               if(data.result){
                  commit('put',entrega_id)
               }
               resolve(data)
            }).catch(e => {
               reject(e)
            })
         })

      },

      fetchDataDetails({commit},entrega_id) {
         return new Promise((resolve, reject) => {
            axios.get(`/api/entregas/${entrega_id}/fetch-data-details`).then(({data}) => {
               commit('setEntrega',data)
               resolve(data);
            })
            .catch(e => reject(e))
         });
      },

      recargarEstatus({commit}, entrega_id) {
         return new Promise((resolve, reject) => {
            axios.get(`/api/entregas/${entrega_id}/get-estatus`).then(({data}) => {
                  if(data.result){
                     commit('updateEstatus',data.estatus)
                  }
                  resolve(data)

            }).catch(e => reject(e));
         })
      },

      cambiarEstatus({commit},{estatus,entrega_id}) {
         return new Promise((resolve, reject) => {
            axios.put(`/api/entregas/${entrega_id}/cambiar-estatus`,{estatus}).then(({data}) => {
               if(data.result){
                  commit('updateEstatus',{estatus:data.estatus,estatus_details:data.estatus_details})
               }
               resolve(data)
            }).catch(e => reject(e))
         })
      },

      guardarTraking({commit},{entrega_id,traking}) {
         return new Promise((resolve, reject) => {
            axios.put(`/api/entregas/${entrega_id}/guardar-traking`,{traking}).then(({data}) => {
               if(data.result){
                  commit('updateTraking',data.traking)
               }
               resolve(data)
            }).catch(e => reject(e))
         })
      },

      cargarArchivo({commit},{entrega_id,archivo}) {
         return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append('archivo',archivo);
            axios.post(`/api/entregas/${entrega_id}/cargar-archivo`,formData).then(({data}) => {

               if(data.result){
                  commit('updateArchivo',data.archivo)
                  commit('setUrlArchivo',data.url_archivo)
               }
               resolve(data)
            }).catch(e => reject(e))
         })
      },

      fetchEntregasCliente({commit},datos){
         return new Promise((resolve, reject) => {
            
            axios.post(`/api/entregas/fetch-entregas-cliente`,datos)
            .then(({data}) => {
               resolve(data)
            }).catch(e => reject(e));

         })
      }


   }
}
