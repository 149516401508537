import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: h => h('font-awesome-icon', { props: { size: 'lg', icon: 'fas fa-xmark' } }),
  },
  OpenIndicator: {
    render: h => h('font-awesome-icon', { props: { size: 'lg', icon: 'fa-angle-down' }, class: 'open-indicator' }),
  },
})

Vue.component(vSelect)
