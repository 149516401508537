import { headers } from "@core/utils/utils"
export default{
  namespaced:true,
  state(){
    return{
      medio:{
        id         : null,
        nombre     : null,
        descripcion: '',
        icono      : null,
        canales    : []
      },
      medios:[],
    }
  },


  getters:{
    draft(state){
      return state.medio
    }
  },

  mutations:{

    clear(state){
      state.medio = {
        id         : null,
        nombre     : null,
        descripcion: '',
        icono      : null,
        canales    : []
      }
    },

    setMedio(state,medio){
      if(medio){
        state.medio = medio
      }
    },
    
    setMedios(state,medios){
      state.medios = medios
    }

  },

  actions:{

    fetch({commit},medio_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/medios/${medio_id}/fetch-data`).then(({data}) => {
          commit('setMedio',data)
          resolve(data)
        }).catch(e  => reject(e))

      })
    },
    
    fetchData({commit},filtro){
      return new Promise((resolve, reject) => {
        axios.post(`/api/medios/fetch-data`,filtro).then(({data})  => {
          commit('setMedios',data.medios)
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    eliminar({commit},medio_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/medios/${medio_id}`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    guardar({commit},datos){

      let formData = new FormData();

      Object.keys(datos).forEach(k => {
        formData.append(k,datos[k]);
      });

      return new Promise((resolve, reject) => {
        if(datos.id){
          formData.append('_method','put');
          axios.post(`/api/medios/${datos.id}`,formData,{headers}).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
        }else{
          axios.post(`/api/medios`,formData,{headers}).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
        }
      });

    },


    cargarMediosLight({commit}){
      return new Promise((resolve, reject) => {
        axios.get(`/api/medios/get-light`).then(({data}) => {
          commit('setMedios',data)
          resolve(data)
        }).catch(e => reject(e))
      })
    }

  }
}