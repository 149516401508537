
import { rols_empleados } from "@core/utils/utils";
export default {

	namespaced:true,

	state : () => ({
		usuario:{
			id:null,
			nombre:'',
      apellido:'',
      email:null,
      telefono:null,
      rol_id:null,
			avatar:null,
      activo:null,
      is_password:false,
      ultimo_login:null,
      estado_id:null,
      ciudad_id:null,
			direccion:'',
      locale:'es',
			cliente:null
		},

		user:{	
			id:null,
			nombre:'',
      apellido:'',
      email:null,
      telefono:null,
      rol_id:null,
			avatar:null,
      activo:null,
      is_password:false,
      ultimo_login:null,
      estado_id:null,
      ciudad_id:null,
			direccion:'',
      locale:'es',
			cliente:null

		},

		usuarios:[]


	}),

	getters:{

		draft(state){
			return clone(state.user);
		},

		draftUsuario(state){
			return clone(state.usuario)
		},

		

		conPermiso:(state) => {
			return (permiso) => {

				if(state.usuario){
					return (state.usuario.roles[0].permissions.find((permission) => permission.name == permiso))
				}

				return false;
				
			}
		},

		getUsuarios: (state) => {
			return (rol) => {
				return state.usuarios.filter(user => {
					

					let i  = user.roles.findIndex((val,i ) => roles.name == rol)
					if(i > 0 ){
						return true;
					}else{
						return false;
					}

				});
			} 
		},

		getUsuario:(state) => {
			return (id) => {
				return state.usuarios.find(user => user.id ==  id);
			}
		},

		getListado:(state) => {
			return (users_id) => {

				var users = [];

				users_id.forEach((e,i) => {

					var us = state.usuarios.find((u) => {

					 return (u.id == e.user_id);
					})

					if(us != undefined){
						if(users.find((u) => u.id == us.id)  == undefined){
							users.push(us);
						}
						
					}
					
				});


				return users;

			}
		},

		isRol(state){
			return (rol) => {
				if(state.usuario.rol){

					if(typeof rol == 'object'){
						return rol.includes(state.usuario.rol.nombre)
					}
					return rol == state.usuario.rol.nombre
				}
				return false;
			}
		},

		getFullName : (state) => `${state.usuario.nombre} ${state.usuario.apellido}`, 

		avatar:(state) => state.usuario.avatar,

		getFilterUsers:(state) => {
			return (roles_name) => {

				let result =  state.usuarios.filter(val => {
					return (roles_name.find(value => value == val.rol.nombre) != undefined) ? true : false
				});
				
				return result.map(val => {
					return {label:val.nombre,value:val.id,id:val.id,email:val.email};
				})
			}
		},

		rolUser(state){
			return state.usuario.rol ? state.usuario.rol.nombre : ''
		},

		rolName(state){
			return state.usuario.rol ? state.usuario.rol.nombre : '';
		},

		getLocale(state){
			return state.usuario.locale
		},
		clientesSeleccionables:(state,getters) => {
      if(getters.isRol(rols_empleados)){
        return state.usuario.clientes.map(v => ({id:v.id,label:v.nombre,logo:v.logo}))
      }
      return []
    },

		isCliente:(state,getters) => {
			if(getters.isRol(rols_empleados)){
				return state.usuario.cliente.id != null
			}
			return false;
		},

		clienteId:(state,getters) => {
			return getters.isCliente ? state.usuario.cliente.id : null
		}

	},

	mutations:{

		cargarUser(state,data){
			localStorage.setItem('userData',JSON.stringify(data))			
			state.usuario = {...state.usuario,...data};
		},

		setTelefono(state,numero){
			state.usuario.telefono = numero
		},

		setUsuarios(state,usuarios){
			if(usuarios.length){
				state.usuarios = usuarios;
			}
		},

		
		setUsuario(state,usuario){
			if(usuario != undefined || usuario != null){
				state.user = usuario
			}
			
		},

		pushUsuario(state,usuario){
			state.usuarios.push(usuario);
		},

		capturarUsuario(state,id_usuario){
			state.user = state.usuarios.find((user) => user.id == id_usuario)
		},

		clearUsuario(state){
			state.user = {
				id:null,
				nombre:'',
				apellido:'',
				email:null,
				telefono:null,
				rol_id:null,
				avatar:null,
				activo:null,
				is_password:false,
				ultimo_login:null,
				estado_id:null,
				ciudad_id:null,
				direccion:'',
				locale:'es',
				cliente:null

			}
		},

		updateUsuario(state,data){
			var i = state.usuarios.findIndex((user) => user.id == data.id);

			if(i != -1){
				state.usuarios[i] = data;
				state.user = data;
			}

		},

		update(state,data){

			var i = state.usuarios.findIndex((user) => user.id == data.id);
			
			if(i != -1){
				state.usuarios[i] = data;
			}

		},

		updateAvatarPerfil(state,avatar){
			const user = JSON.parse(localStorage.getItem('userData'))
			user.avatar = avatar
			localStorage.setItem('userData',JSON.stringify(user))
			state.usuario.avatar = avatar;

		},	

		actualizarHabilildades(state,data){
			const user = JSON.parse(localStorage.getItem('userData'))
			user.habilidades = data
			localStorage.setItem('userData',JSON.stringify(user))
			state.usuario.habilidades = data;
		},

		actualizarAvatarDeUsuario(state,avatar){
			let user = state.usuarios.find(val => val.id == state.user.id)

			if(user != undefined){
				user.avatar = avatar
			}

		},

		updatePerfil(state,data){
			localStorage.setItem('userData',JSON.stringify(data))
			state.usuario = {...state.usuario,...data}
		},

	


		desactivarCuenta(state, result) {
			const user = JSON.parse(localStorage.getItem('userData'))
			user.activo = !result;
			localStorage.setItem('userData', JSON.stringify(user))
			state.usuario.activo = !result;

		},

		limpiarUsuario(state){

			state.usuario = {
				id:null,
				nombre:'',
				apellido:'',
				email:null,
				telefono:null,
				rol_id:null,
				avatar:null,
				activo:null,
				is_password:false,
				ultimo_login:null,
				estado_id:null,
				ciudad_id:null,
				direccion:'',
				locale:'es',
				cliente:null

			}

		},

		updateLocale(state,locale){
			localStorage.setItem('locale',locale)
			state.usuario.locale = locale
		},

		updateAvatar(state,{user_id,avatar}){
			if(state.user.id === user_id){
				state.user.avatar = avatar
			}

		}
	},

	actions:{

		updateLocale({commit},{usuario,locale}){

			return new Promise((resolve, reject) => {
				axios.get(`/api/usuarios/${usuario}/update-locale/${locale}`).then(({data}) => {
					if(data.result){
						commit('updateLocale',locale)
					}
					resolve(data)
				}).catch(e => reject(e))
				
			})
		},

		cargarLightUsers({commit}){

			return new Promise((resolve, reject) => {
				
				axios.get(`/api/usuarios/get/list-select-light`).then(({data}) => {
					resolve(data)
				}).catch((e) => reject(e))

			})
		},

		cargarUsuarios({state,commit}){

			return new Promise((resolve, reject) => {
				axios.get('/api/usuarios/all').then(({data}) => {
					commit('setUsuarios',data);
					resolve(data)
				}).catch( e => reject(e))
			})

		},

		cargarUsuario({state,commit,dispatch}){

				// return await axios.get('/app/get/data');
				let options = {
					'headers': {
						'WWW-Authenticate': 'Bearer', 'Authorization': localStorage.getItem('token')
					}
				}
				return new Promise((resolve, reject) => {
					axios.get(`/api/auth/user/`,null,options).then(({data}) => {
						commit('cargarUser',data)
						resolve(data)
					}).catch(e => reject(e))

				})

		},

		async guardar({state,commit,dispatch},datos){

			return new Promise((resolve, reject) => {
				if (datos.id) {
						
						axios.put(`/api/usuarios/` + datos.id, datos).then(({data}) => {
							resolve(data)
						}).catch(e => reject(e))
				} else {
					
					axios.post('/api/usuarios', datos).then(({data}) => {
						resolve(data)
					 }).catch(e => reject(e))
				}
			
			})
			
	   
		},

		async guardarUsuario({state,commit},datos){

			return await new Promise((resolve, reject) => {
				axios.put(`/api/perfil/update/usuario/${state.usuario.id}`,datos).then(({data}) => {
					if(data.result){
						commit('updatePerfil',data.usuario)
					}
					resolve(data)
				}).catch(e => reject(e))

			})
		},

		async cambiarContrasena({commit,state},data){
			return await new Promise((resolve, reject) => {
				axios.put(`/api/cambiar/contrasena/usuario/${state.usuario.id}`,data).then(({data}) => {
					resolve(data)
				}).catch(e => reject(e))

			})
		},
		 

		async eliminar({state},usuario_id){

			return await new Promise((resolve, reject) => {
				axios.delete(`/api/usuarios/${usuario_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
			})

		},

		updateAvatarUser({state,commit},datos){

			return new Promise((resolve, reject) => {
				let form = new FormData();
				form.append('imagen', datos.imagen);

				axios.post(`/api/usuario/${datos.user_id}/update/avatar`,form,{
					headers: {
						'Content-Type': 'multipart/form-data; boundary=something'
					}
				}).then(({data}) => {
					if(data.result){
						commit('updateAvatar',{user_id:datos.user_id,avatar:data.avatar})
						if(state.usuario.id === datos.user_id){
							commit('updateAvatarPerfil',data.avatar)
						}
					}
					resolve(data)
				})
				.catch(e => reject(e));

			});

		},

		fetchData({state,commit},datos){

				return new Promise((resolve, reject) => {
					axios.post('/api/usuarios/fetch-data', datos)
						.then(({data}) => {
							resolve(data)
						})
						.catch(error => reject(error))
				});
		},

		fetch({state,commit},usuario_id){
			return new Promise((resolve, reject) => {
				axios.get(`/api/usuarios/${usuario_id}/fetch-data`).then(({data}) => {
					commit('setUsuario',data)
					resolve(data)
				}).catch(e => reject(e))
			})
		},

		fetchDataUserForEmail({state,commit},email){
			return new Promise((resolve, reject) => {
				
				axios.get(`/api/usuarios/for-email/${email}/fetch-data`).then(({data}) => {
					commit('setUsuario',data)
					resolve(data)
				}).catch(e => reject(e))

			})
		},

		async desactivarCuenta({commit},data) {
			return await new Promise((resolve, reject) => {
				axios.post(`/api/desactivar/usuario`,data).then(({data:datos}) => {
					commit('desactivarCuenta',datos.result)
					resolve(datos)
				}).catch( e => reject(e))
			})
		},

		async nuevoUsuario({commit},datos){

			return await new Promise((resolve, reject) => {
				axios.post('/api/auth/nuevo/usuario',datos).then(({data}) => {
					resolve(data)
				}).catch(e => {
					reject(e)
				})

			})

		},

		fetchUser({state,commit},id_user){
			return new Promise((resolve, reject) => {
				axios.get(`/api/usuarios/${id_user}/fetch-data-user`).then(({data}) => {
					commit('setUsuario',data)
					resolve(data)
				}).catch(e => reject(e))
			})
		},


		verificarStatus({state,commit},usuario_id){
			return new Promise((resolve, reject) => {
				axios.get(`/api/usuarios/${usuario_id}/check/is-verified`).then(({data}) => {resolve(data)}).catch(e => reject(e))
			})
		},

		cambiarStatus({commit},datos){
			return new Promise((resolve, reject) => {
				axios.put(`/api/usuarios/${datos.user_id}/change-status`,datos).then(({data}) => resolve(data))
				.catch(e => reject(e))
			})
		},

		getListPorRol({commit},rols){
			return new Promise((resolve, reject) => {
				if(typeof rols == 'object'){
					axios.post(`/api/usuarios/rols/get-list-select`,{rols:rols}).then(({data}) => {
						resolve(data)
					}).catch(e => reject(e))
				}else{
					axios.get(`/api/usuarios/rol/${rol_name}/get-list-select`).then(({data}) => {
						resolve(data)
					}).catch(e => reject(e))
				}
			})
		},

		getListPorRolDesempleados({commit},rols){
			return new Promise((resolve, reject) => {
				if(typeof rols == 'object'){
					axios.post(`/api/usuarios/rols/get-list-select-desempleados`,{rols:rols}).then(({data}) => {
						resolve(data)
					}).catch(e => reject(e))
				}else{
					axios.get(`/api/usuarios/rol/${rol_name}/get-list-select-desempleados`).then(({data}) => {
						resolve(data)
					}).catch(e => reject(e))
				}
			})
		},

		asignarHabilidades({commit},responsabilidad_id){
			return new Promise((resolve, reject) => {
				axios.get(`/api/usuarios/responsabilidads/${responsabilidad_id}/get-habilidades-por-rol`).then(({data}) => {
					commit('actualizarHabilildades',data);
					resolve(data)
				}).catch(e => reject(e))
			})
		}
	
	}
}