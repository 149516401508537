export default{
  namespaced:true,
  state(){
    return{
      iva:{
        id:null,
        nombre:null,
        porcentaje:0,
        estado_id:null,
        pais_id:null,
        estado:null,
        pais:null

      },
      ivas:[],
    }
  },


  getters:{
    draft(state){
      return state.iva
    }
  },

  mutations:{

    clear(state){
      state.iva = {
        id:null,
        nombre:null,
        porcentaje:0,
        estado_id:null,
        pais_id:null,
        estado:null,
        pais:null
      }
    },

    setIva(state,iva){
      if(iva){
        state.iva = iva
      }
    },
    
    setIvas(state,ivas){
      state.ivas = ivas
    },

    put(state,iva_id){

      if(state.ivas.length){
        state.ivas.splice(
          state.ivas.findIndex(i => i.id === iva_id),
          1
        )
      }
    },

  },

  actions:{

    fetch({commit},iva_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/ivas/${iva_id}/fetch-data`).then(({data}) => {
          commit('setIva',data)
          resolve(data)
        }).catch(e  => reject(e))

      })
    },

    lightIvas({commit}){
      return new Promise((resolve, reject) => {
        axios.get(`/api/ivas/fetch-data-light`).then(({data}) => resolve(data)).catch(e => reject(e));
      })
    },

    
    fetchData({commit},filtro){
      return new Promise((resolve, reject) => {
        axios.post(`/api/ivas/fetch-data`,filtro).then(({data})  => {
          commit('setIvas',data.ivas)
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    eliminar({commit},iva_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/ivas/${iva_id}`).then(({data}) => {
          if(data.result){
            commit('put',iva_id)
          }
          resolve(data)
        }).catch(e => reject(e))
      })
    },


   guardar({commit},datos){
    return new Promise((resolve, reject) => {
      if(datos.id){
        axios.put(`/api/ivas/${datos.id}`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      }else{
        axios.post(`/api/ivas`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      }
    })
   }
  }
}