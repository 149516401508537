import store from '@/store';
import Error404 from '@/views-core/error/Error404.vue'
import Login from '@/views-core/pages/authentication/Login.vue';
import ForgotPassword from 'views/auth/ForgotPassword.vue'
import PasswordReset from 'views/auth/PasswordReset.vue';
import EstablecerContrasena from 'views/auth/EstablecerContrasena.vue'
import NotAuthorized from '@/views-core/pages/miscellaneous/NotAuthorized.vue'

export default [
   {
      path:'/',
      name:'home',
      component: () => import('views/home.vue'),
      meta:{
         resource:'Home',
         action:'read',
      },
   },

   {
    path: '/error-404',
    name: 'error-404',
    component:Error404,
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'login',
    component:Login,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: ForgotPassword,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/reset-password',

    props: (route) => ({
      token: (route.query.token),
      email: (route.query.email),
    }),
    name: 'auth-reset-password',
    component: PasswordReset,
    meta: {
      layout: 'full',
      resource:'Auth'
    },
  },

  {
    path: '/usuario/:usuario/establecer/contrasena',
    props:true,
    name: 'establecer-contrasena',
    component:EstablecerContrasena,
    meta: {
      layout: 'full',
      resource: 'Auth'
    },
  },

 
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component:NotAuthorized,
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },

  
  
   /*****************************************/
   /* MI Perfil
   /*************************************** */

   {
      path:'/perfil',
      name: 'mi-perfil',
      component: () => import('views/perfil/index.vue'),
      meta: {
         pageTitle: 'Mi perfil',
         resource: 'Perfil',
         action: 'read',
         breadcrumb: [
         {
            text: 'Configuración de perfil',
            active: true,
         },
         ],
      },
   },

  

   /*****************************************/
   /* USUARIO
   /*************************************** */

   {
      path:'/usuarios',
      component: () => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'listar.usuarios',
            props:{tipoUser:'Administradores'},
            component: () => import('views/usuarios/lists.vue'),
            meta:{
               resource: 'Usuarios',
               action: 'read',
               pageTitle:'Usuarios',
               breadcrumb:[
                  {
                     text:'listado',
                     active:true
                  }
               ]
            }
         },

         {
            path:'create',
            name:'create.usuario',
            props:true,
            component: () => import('views/usuarios/create.vue'),
            meta:{
               pageTitle: `Registrar nuevo usuario`,
               navActiveLink: 'listar.usuarios',
               resource: 'Usuarios',
               action: 'write',
               breadcrumb: [
                  {
                     text: 'Usuarios',
                     active: false,
                     to:{name:'listar.usuarios'}
                  },

                  {
                     text: 'crear',
                     active: true
                  }
               ]
            }
            

         },
         {
            path: ':id/edit',
            prop:true,
            name: 'edit.usuario',
            component: () => import('views/usuarios/edit.vue'),
            beforeEnter:(to,from,next) => {
               store.dispatch('usuario/fetch',to.params.id).then((data) => {
                  if(data){
                     next()
                  }else{
                     next({name:'error-404'})
                  }
               }).catch(e => next({name:'error-404'}))
               
            },

            meta: {
               pageTitle: `Editar regitro de usuario`,
               navActiveLink: 'listar.usuarios',
               resource: 'Usuarios',
               action: 'update',
               breadcrumb: [

                  {
                     text: 'Usuarios',
                     active: false,
                     to:{name:'listar.usuarios'}
                  },

                  {
                     text: 'editar',
                     active: true
                  }
               ]
            }
         }
      ]
   },
   
   /*****************************************/
   /* ROL DE USUARIO
   /*************************************** */

   {
      path: '/roles',
      component: () => import(  'views/roles/index.vue'),
      exact:false,
      children: [
         {
            path: '',
            name: 'listar.roles',
            component: () => import('views/roles/lists.vue'),
            meta: {
               pageTitle: 'Rol de usuarios',
               breadcrumb: [
                  {
                     text: 'Listado',
                     active: true,
                  },
               ],
               resource: 'Roles',
               action: 'read'
            }
         },
         {
            path: 'create',
            name: 'create.role',
            component: () => import('views/roles/create.vue'),
            meta: {
               pageTitle: 'Crear Rol',
               navActiveLink:'listar.roles',
               breadcrumb: [
                  {
                     text: 'Rol de usuarios',
                     active: false,
                     to: { name: 'listar.roles' }
                  },

                  {
                     text: 'Crear',
                     active: true,
                  },
               ],
               resource: 'Roles',
               action: 'write'
            }
         },
         {
            path: ':id/edit',
            props:true,
            name: 'edit.role',
            component: () => import('views/roles/edit.vue'),
            beforeEnter: (to, from, next) => {
               store.dispatch('rol/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },

            meta: {
               pageTitle: 'Editar Rol',
               navActiveLink: 'listar.roles',
               breadcrumb: [
                  {
                     text: 'Rol de usuarios',
                     active: false,
                     to: { name: 'listar.roles' }
                  },

                  {
                     text: 'Editar',
                     active: true,
                  },
               ],
               resource: 'Roles',
               action: 'update'
            }
         },

      ]
   },

  /*****************************************/
   /* PERMISOS DE USUARIO
   /*************************************** */

   {
      path: '/permisos',
      component: () => import('views/permisos/index.vue'),
      exact: false,
      children: [
         {
            path: '',
            name: 'listar.permisos',
            component: () => import('views/permisos/lists.vue'),
            meta: {
               pageTitle: 'Permisos de usuario',
               breadcrumb: [
                  {
                     text: 'Listado',
                     active: true,
                  },
               ],
               resource: 'Permisos',
               action: 'read'
            }
         },
         {
            path: 'create',
            name: 'create.permiso',
            component: () => import('views/permisos/create.vue'),
            meta: {
               pageTitle: 'Crear Permiso',
               navActiveLink: 'listar.permisos',
               breadcrumb: [
                  {
                     text: 'Permisos de usuario',
                     active: false,
                     to: { name: 'listar.permisos' }
                  },

                  {
                     text: 'Crear',
                     active: true,
                  },
               ],
               resource: 'Permisos',
               action: 'write'
            }
         },
         {
            path: ':id/edit',
            props: true,
            name: 'edit.permiso',
            component: () => import('views/permisos/edit.vue'),
            beforeEnter: (to, from, next) => {
                  store.dispatch('permiso/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
            },
            meta: {
               pageTitle: 'Editar Permiso',
               navActiveLink: 'listar.permisos',
               breadcrumb: [
                  {
                     text: 'Permisos de usuario',
                     active: false,
                     to: { name: 'listar.permisos' }
                  },

                  {
                     text: 'Editar',
                     active: true,
                  },
               ],
               resource: 'Permisos',
               action: 'update'
            }
         },

         

      ]
   },

    /*****************************************/
   /* IVas
   /*************************************** */

   {
      path: '/ivas',
      component: () => import('components/Views.vue'),
      exact: false,
      children: [
         {
            path: '',
            name: 'iva.listar',
            component: () => import('views/ivas/list.vue'),
            meta: {
               pageTitle: 'Ivas',
               breadcrumb: [
                  {
                     text: 'Listado',
                     active: true,
                  },
               ],
               resource: 'Ivas',
               action: 'read'
            }
         },
         {
            path: 'create',
            name: 'create.iva',
            component: () => import('views/ivas/create.vue'),
            meta: {
               pageTitle: 'Crear Iva',
               navActiveLink: 'iva.listar',
               breadcrumb: [
                  {
                     text: 'Listado de Ivas',
                     active: false,
                     to: { name: 'iva.listar' }
                  },

                  {
                     text: 'Crear',
                     active: true,
                  },
               ],
               resource: 'Ivas',
               action: 'write'
            }
         },
         {
            path: ':id/edit',
            props: true,
            name: 'edit.iva',
            component: () => import('views/ivas/edit.vue'),
            beforeEnter: (to, from, next) => {
                  store.dispatch('iva/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
            },
            meta: {
               pageTitle: 'Editar Iva',
               navActiveLink: 'iva.listar',
               breadcrumb: [
                  {
                     text: 'Listado de Ivas',
                     active: false,
                     to: { name: 'iva.listar' }
                  },

                  {
                     text: 'Editar',
                     active: true,
                  },
               ],
               resource: 'Ivas',
               action: 'update'
            }
         },

         

      ]
   },




   /*****************************************/
   /* Sistema 
   /*************************************** */
   {
      path:'/sistema',
      name:'sistema',
      component:() => import('views/sistema.vue'),
      beforeEnter: (to, from, next) => {
         store.dispatch('sistema/fetch').then(({id}) => {
            if(id){
               next()
            }else{
               next({name:'home'})
            }

         }).catch(e => {
            next(({name:'home'}))
         });

      },
      meta:{
         pageTitle:'Sistema',
         resource:'Sistema',
         action:'read'
      }
   },

   /*****************************************/
   /* Idiomas
   /*************************************** */

   {
      path:'/idiomas',
      component:() => import('views/idiomas/index.vue'),
      children:[
         {
            path:'',
            name:'idioma.list',
            component:() => import('views/idiomas/list.vue'),
            meta:{
               pageTitle:'Idiomas del sistema',
               resource:'Idiomas',
               action:'read',
               breadcrumb:[
                  {text:'Listado',active:true}
               ]
            }
         },

         {
            path:'traduccions',
            name:'idioma.traducciones',
            props: (route) => ({ lang: route.query.lang }),
            component:() => import('views/idiomas/traducciones.vue'),
            meta:{
               pageTitle:'Traducciones del idioma',
               navActiveLink:'idioma.list',
               resource:'Idiomas',
               action:'read',
               breadcrumb:[
                  {text:'Listado',active:false,to:{name:'idioma.list'}},
                  {text:'Traducciones',active:true},
               ]
            }
         },

         {
            path:'create',
            name:'idioma.create',
            component:() => import('views/idiomas/create.vue'),
            meta:{
               pageTitle:'Crear',
               resource:'Idiomas',
               navActiveLink:'idioma.list',
               action:'read',
               breadcrumb:[
                  {text:'Listado',active:false,to:{name:'idioma.list'}},
                  {text:'Crear',active:true},
               ]
            }
         },

          {
            path:':id/edit',
            name:'idioma.edit',
            props:true,
            component:() => import('views/idiomas/edit.vue'),
            meta:{
               pageTitle:'Editar',
               resource:'Idiomas',
               navActiveLink:'idioma.list',
               action:'read',
               breadcrumb:[
                  {text:'Listado',active:false,to:{name:'idioma.list'}},
                  {text:'Editar',active:true},
               ]
            }
         }


      ]
   },



   /*****************************************/
   /* Billetera 
   /*************************************** */

   {
      path:'/estado-cuenta',
      component:() => import('components/Views.vue'),

      children:[
         {
            path:'',
            name:'billetera',
            component:() => import('views/estado-cuentas/list.vue'),
            meta:{
               pageTitle:"Estado de cuenta",
               resource:'billetera',
               action:'read'
            }
         }
      ]
   },


   /*****************************************/
   /* Pagos 
   /*************************************** */

   {
      path:'/pagos',
      component:() => import('components/Views.vue'),

      children:[
         {
            path:'',
            name:'pago.list',
            component:() => import('views/pagos/list.vue'),
            meta:{
               pageTitle:"Pagos",
               resource:'Pagos',
               action:'read'
            }
         }
      ]
   },


    
   /*****************************************/
   /* Tipo de Clientes
   /*************************************** */
   {
      path:'/tipo-clientes',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'tipo_cliente.list',
            component:() => import('views/tipo_clientes/list.vue'),
            meta:{
               resource:'Tipos de clientes',
               action:'read',
               pageTitle:'Tipos de Clientes'
            }
         },
         {
            path:'create',
            name:'create.tipo_cliente',
            component:() => import('views/tipo_clientes/create.vue'),
            meta:{
               resource:'Tipos de clientes',
               action:'create',
               pageTitle:'Registrar tipo de clientes',
               navActiveLink:'tipo_cliente.list',
               breadcrumb:[
                  {text:'Listado',to:{name:'tipo_cliente.list'},active:false},
                  {text:'Crear',active:true},
               ]
            }
         },
          {
            path:':id/edit',
            name:'edit.tipo_cliente',
            props:true,
            beforeEnter:(to,from,next) => {
               store.dispatch('tipo_cliente/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },

            component:() => import('views/tipo_clientes/edit.vue'),
            meta:{
               resource:'Tipos de clientes',
               action:'update',
               pageTitle:'Actualizar tipo de cliente',
               navActiveLink:'tipo_cliente.list',
               breadcrumb:[
                  {text:'Listado',to:{name:'tipo_cliente.list'},active:false},
                  {text:'Editar',active:true},
               ]
            }
         }


      ]
   },

   /*****************************************/
   /* Clientes
   /*************************************** */
   {
      path:'/clientes',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'cliente.list',
            component:() => import('views/clientes/list.vue'),
            meta:{
               resource:'Clientes',
               action:'read',
               pageTitle:'Clientes'
            }
         },
         {
            path:'create',
            name:'create.cliente',
            component:() => import('views/clientes/create.vue'),
            meta:{
               resource:'Clientes',
               action:'create',
               pageTitle:'Registrar Cliente',
               navActiveLink:'cliente.list',
               breadcrumb:[
                  {text:'Listado',to:{name:'cliente.list'},active:false},
                  {text:'Crear',active:true},
               ]
            }
         },
          {
            path:':id/edit',
            name:'edit.cliente',
            props:true,
            beforeEnter:(to,from,next) => {
               store.dispatch('cliente/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },

            component:() => import('views/clientes/edit.vue'),
            meta:{
               resource:'Clientes',
               action:'update',
               pageTitle:'Actualizar cliente',
               navActiveLink:'cliente.list',
               breadcrumb:[
                  {text:'Listado',to:{name:'cliente.list'},active:false},
                  {text:'Editar',active:true},
               ]
            }
         },
         {
            path:':id/show',
            name:'show.cliente',
            props:true,
            beforeEnter:(to,from,next) => {
               store.dispatch('cliente/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },

            component:() => import('views/clientes/show.vue'),
            meta:{
               resource:'Clientes',
               action:'read',
               pageTitle:'Perfil del cliente',
               navActiveLink:'cliente.list',
               breadcrumb:[
                  {text:'Listado',to:{name:'cliente.list'},active:false},
                  {text:'Datos',active:true},
               ]
            }
         }

      ]
   },

   /*****************************************/
   /* Banco audio visual
   /*************************************** */
   {
      path:'/banco-audiovisual',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'banco.list',
            component:() => import('views/banco-audiovisuals/list.vue'),
            meta:{
               pageTitle:'Bancos Audiovisuales',
               resource:'Banco audiovisual',
               action:'read',
               breadcrumb:[
                  {text:'Directorio',active:true}
               ]
            }
         },
         {
            path:'cliente/directory',
            props:(route) => ({
               disk:route.query.disk,
               directory:route.query.directory
            }),
            name:'banco.directorio',
            component:() => import('views/directorios/directorio.vue'),
            meta:{
               pageTitle:'Directorio',
               resource:'Directorio',
               action:'read',
               navActiveLink:'banco.list',
               breadcrumb:[
                  {text:'Banco audiovisual',active:false,to:{name:'banco.list'}},
                  {text:({query}) => query.disk,active:true},
               ]
            },
         },
         {
            path:'cliente/directory/:carpetaId/archivos',
            props:(route) => ({
               carpetaId:route.params.carpetaId,
               disk:route.query.disk,
            }),
            name:'banco.directorio.archivos',
            component:() => import('views/directorios/archivos.vue'),
            beforeEnter:(to,from,next) => {
               store.dispatch('carpeta/fetch',to.params.carpetaId).then(({id}) => {
                  if(id){
                     next();
                  }
               })
            },

            meta:{
               pageTitle:'Archivos',
               resource:'Directorio',
               action:'read',
               navActiveLink:'banco.list',
               breadcrumb:[
                  {text:'Banco audiovisual',active:false,to:{name:'banco.list'}},
                  {text:'Archivos',active:true},
               ]
            }
         }
      ]
   },


   /*****************************************/
   /* Modulo de Tv Displays
   /*************************************** */
   {
      path:'/tv-displays',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'',
            component:() => import('views/tv-displays/list.vue'),
            name:'tv_display.list',
            meta:{
               resource:'Tv displays',
               action:'read',
               pageTitle:'Tv Displays'
            }
         },

          {
            path:'create',
            component:() => import('views/tv-displays/create.vue'),
            name:'tv_display.create',
            meta:{
               resource:'Tv displays',
               action:'read',
               pageTitle:'Registrar',
               navActiveLink:'tv_display.list',
               breadcrumb:[
                  {text:'Listado',to:{name:'tv_display.list'},active:false},
                  {text:'Crear',active:true},
               ]
            }
         },

           {
            path:':id/edit',
            component:() => import('views/tv-displays/edit.vue'),
            name:'tv_display.edit',
            props:true,
            beforeEnter:(to,from,next) => {
               store.dispatch('display/fetch',to.params.id).then(({id,regions}) => {
                  if(id){
                     store.commit('region/setRegions',regions)
                     next()
                  }
               }).catch(e => console.log(e))
            },
            meta:{
               resource:'Tv displays',
               action:'update',
               pageTitle:'Editar',
               navActiveLink:'tv_display.list',
               breadcrumb:[
                  {text:'Listado',to:{name:'tv_display.list'},active:false},
                  {text:'Editar',active:true},
               ]
            }
         }


      ]
   },

   /*****************************************/
   /* Categorías de productos
   /*************************************** */
   {
      path:'/categorias',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'',
            component:() => import('views/categorias/list.vue'),
            name:'categoria.list',
            meta:{
               resource:'Categorías',
               action:'read',
               pageTitle:'Categorías'
            }
         },

          {
            path:'create',
            component:() => import('views/categorias/create.vue'),
            name:'categoria.create',
            meta:{
               resource:'Categorías',
               action:'read',
               pageTitle:'Registrar',
               navActiveLink:'categoria.list',
               breadcrumb:[
                  {text:'Listado',to:{name:'categoria.list'},active:false},
                  {text:'Crear',active:true},
               ]
            }
         },

           {
            path:':id/edit',
            component:() => import('views/categorias/edit.vue'),
            name:'categoria.edit',
            props:true,
            beforeEnter:(to,from,next) => {
               store.dispatch('categoria/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               }).catch(e => console.log(e))
            },
            meta:{
               resource:'Categorías',
               action:'update',
               pageTitle:'Editar',
               navActiveLink:'categoria.list',
               breadcrumb:[
                  {text:'Listado',to:{name:'categoria.list'},active:false},
                  {text:'Editar',active:true},
               ]
            }
         }
      ]
   },

   /*****************************************/
   /* Productos
   /*************************************** */
   {
      path: '/productos',
      component: () => import('components/Views.vue'),
      children: [
         {
            path: '',
            component: () => import('views/productos/list.vue'),
            name: 'producto.list',
            meta: {
               resource: 'Productos',
               action: 'read',
               pageTitle: 'Productos',
               breadcrumb: [
                  { text: 'Home', to: { name: 'home' }, active: false },
                  { text: 'Productos', active: true },
               ]
            }
         },
         {
            path: 'create',
            component: () => import('views/productos/create.vue'),
            name: 'producto.create',
            meta: {
               resource: 'Productos',
               action: 'write',
               pageTitle: 'Agregar Producto',
               navActiveLink: 'producto.list',
               breadcrumb: [
                  { text: 'Home', to: { name: 'home' }, active: false },
                  { text: 'Productos', active: false, to: { name: 'producto.list' } },
                  { text: 'Crear Producto', active: true },

               ]
            }
         },
         {
            path: ':id/edit',
            props: true,
            component: () => import('views/productos/edit.vue'),
            name: 'producto.edit',
            beforeEnter:(to,from,next) => {
               store.dispatch('producto/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },
            meta: {
               resource: 'Productos',
               action: 'update',
               pageTitle: 'Actualizar Producto',
               navActiveLink: 'producto.list',
               breadcrumb: [
                  { text: 'Home', to: { name: 'home' }, active: false },
                  { text: 'Productos', active: false, to: { name: 'producto.list' } },
                  { text: 'Actualizar', active: true },
               ]
            }
         },
         {
            path: ':id/imagenes',
            props: true,
            component: () => import('views/productos/imagenes.vue'),
            name: 'producto.imagenes',
            beforeEnter:(to,from,next) => {
               store.dispatch('producto/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },
            meta: {
               resource: 'Productos',
               action: 'update',
               pageTitle: 'Imagenes del Producto',
               navActiveLink: 'producto.list',
               breadcrumb: [
                  { text: 'Home', to: { name: 'home' }, active: false },
                  { text: 'Productos', active: false, to: { name: 'producto.list' } },
                  { text: 'Imagenes', active: true },

               ]
            }
         },
         {
            path: ':id/show',
            props: true,
            component: () => import('views/productos/perfil.vue'),
            name: 'producto.show',
            beforeEnter:(to,from,next) => {
             store.dispatch('producto/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },
            meta: {
               resource: 'Productos',
               action: 'read',
               pageTitle: 'Producto',
               navActiveLink: 'producto.list',
               breadcrumb: [
                  { text: 'Home', to: { name: 'home' }, active: false },
                  { text: 'Productos', active: false, to: { name: 'producto.list' } },
                  { text: 'Perfil', active: true },

               ]
            }
         }
      ]
   },

   /*****************************************/
   /* Planes de transmision
   /*************************************** */

   {
      path:'/planes-transmision',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'planes.transmision.list',
            component:() => import('views/planes-transmision/list.vue'),
            meta:{
               pageTitle:'Planes de transmisión',
               resource:'Planes de transmisión',
               action:'read',
               breadcrumb:[
                  {text:'Listado',active:true}
               ]
            }
         },
         {
            path:'create',
            name:'planes.transmision.create',
            component:() => import('views/planes-transmision/create.vue'),
            meta:{
               pageTitle:'Crear Plan de transmisión',
               resource:'Planes de transmisión',
               action:'write',
               navActiveLink:'planes.transmision.list',
               breadcrumb:[
                  {text:'Listado',to:{name:'planes.transmision.list'},active:false},
                  {text:'Crear',active:true}
               ]
            }
         },
         {
            path:':id/edit',
            name:'planes.transmision.edit',
            props:true,
            component:() => import('views/planes-transmision/edit.vue'),
            beforeEnter:(to,from,next) => {
               store.dispatch('plan/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },
            meta:{
               pageTitle:'Editar Plan de transmisión',
               resource:'Planes de transmisión',
               action:'update',
               navActiveLink:'planes.transmision.list',
               breadcrumb:[
                  {text:'Listado',to:{name:'planes.transmision.list'},active:false},
                  {text:'Editar',active:true}
               ]
            }
         }
      ]
   },

   /*****************************************/
   /* Divisas
   /*************************************** */
   {
      path:'/divisas',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'divisas.list',
            component:() => import('views/divisas/list.vue'),
            meta:{
               pageTitle:'Divisas',
               resource:'Divisas',
               action:'read',

               breadcrumb:[
                
                  {
                     text:'Listado',
                     active:true
                  }
               ]
            }
         },

         {
            path: 'create',
            name: 'create.divisa',
            component: () => import('views/divisas/create.vue'),
            meta: {
               pageTitle: 'Registrar Divisa',
               resource: 'Divisas',
               action: 'write',
               navActiveLink:'divisas.list',
               breadcrumb: [
                 
                  {
                     text: 'Listado',
                     active: false,
                     to: {name:'divisas.list'},

                  },
                  {
                     text: 'Crear',
                     active: true,

                  }
               ]
            }
         },

         {
            path:':id/edit',
            name:'divisa.edit',
            props:true,
            component: () => import('views/divisas/edit.vue'),
            beforeEnter:(to,from,next) => {
               store.dispatch('divisa/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },
            meta: {
               pageTitle: 'Editar Divisa',
               resource: 'Divisas',
               action: 'update',
               navActiveLink: 'divisas.list',
               breadcrumb: [
                 
                 {
                     text: 'Listado',
                     active: false,
                     to: {name:'divisas.list'},
                  },
                  {
                     text: 'Editar',
                     active: true,

                  }
               ]
            }
         }


      ]

      
   },

   /*****************************************/
   /* Compras
   /*************************************** */
   {  
      path:'/compras',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'compra.list',
            component:() => import('views/compras/list.vue'),
            meta:{
               pageTitle:'Compras',
               resource:'Compras',
               action:'read',
               breadcrumb:[
                  {
                     text:'Home',
                     to:'home',
                     active:false,
                  },
                  {
                     text:'Listado',
                     active:true
                  }
               ]
            }
         }
      ]

   },

   /*****************************************/
   /* Envios
   /*************************************** */
   {
      path:'/entregas',
      component: () => import('components/Views.vue'),
      children:[
         {
            path:'',
            component:() => import('views/entregas/list.vue'),
            name:'entregas.list',
            meta:{
               pageTitle:'Entregas',
               resource:'Entregas',
               action:'read',
               breadcrumb:[
                  {
                     text:'Listado',
                     active:true
                  }
               ]
            }
         }
      ]
   },


   /*****************************************/
   /* Servicios
   /*************************************** */
   {
      path:'/servicios',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'', 
            name:'servicio.list',
            component:() => import('views/servicios/list.vue'),
            meta:{
               pageTitle:'Servicios',
               resource:'Servicios',
               action:'read',
               breadcrumb:[
                  {
                     text:'Home',
                     to:'home',
                     active:false,
                  },
                  {
                     text:'Listado',
                     active:true
                  }
               ]
            }
         },{
            path:'create',
            name:'servicio.create',
            component:() => import('views/servicios/create.vue'),
            meta:{
               pageTitle:'Servicios',
               resource:'Servicios',
               action:'write',
               navActiveLink:'servicio.list',
               breadcrumb:[
                  {
                     text:'Home',
                     to:'home',
                     active:false,
                  },
                  {
                     text:'Listado',
                     to:{name:'servicio.list'},
                     active:false
                  },
                  {
                     text:'Crear',
                     active:true
                  }
               ]
            }
         },
         {
            path:':id/edit',
            name:'servicio.edit',
            props:true,
            component:() => import('views/servicios/edit.vue'),
            beforeEnter:(to,from,next) => {
               store.dispatch('servicio/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },
            meta:{
               pageTitle:'Servicios',
               resource:'Servicios',
               action:'update',
               navActiveLink:'servicio.list',
               breadcrumb:[
                  {
                     text:'Listado',
                     to:{name:'servicio.list'},
                     active:false
                  },
                  {
                     text:'Editar',
                     active:true
                  }
               ]
            }

         }]
   },



   /*****************************************/
   /* Widgets
   /*************************************** */

   {
      path:'/widgets',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'widget.list',
            component:() => import('views/widgets/list.vue'),
            meta:{
               pageTitle:'Widgets',
               resource:'Widgets',
               action:'read',
               breadcrumb:[
                  {
                     text:'Listado',
                     active:true
                  }
               ]
            }
         },

         {
            path:'create',
            name:'widget.create',
            component:() => import('views/widgets/create.vue'),
            meta:{
               pageTitle:'Crear Widget',
               resource:'Widgets',
               action:'write',
               navActiveLink:'widget.list',
               breadcrumb:[
                  {
                     text:'Listado',
                     to:{name:'widget.list'},
                     active:false
                  },
                  {
                     text:'Crear',
                     active:true
                  }
               ]
            }
         },

         {
            path:':id/edit',
            name:'widget.edit',
            props:true,
            component:() => import('views/widgets/edit.vue'),
            beforeEnter:(to,from,next) => {
               store.dispatch('widget/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },
            meta:{
               pageTitle:'Editar Widget',
               resource:'Widgets',
               action:'update',
               navActiveLink:'widget.list',
               breadcrumb:[
                  {
                     text:'Listado',
                     to:{name:'widget.list'},
                     active:false
                  },
                  {
                     text:'Editar',
                     active:true
                  }
               ]
            }
         }
      ]
   },


   /*****************************************/
   /* Solicitudes de transmisiones
   /*************************************** */
   {
      path:'/solicitudes-transmision',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'solicitudes',
            component:() => import('views/solicitudes/list.vue'),
            meta:{
               resource:'Solicitudes de transmisión',
               action:'read',
               pageTitle:'Solicitudes de transmisión',
               breadcrumb:[
                  {text:'Listado de solicitudes',active:true},
               ]
            }
         }
      ]
   },


    /*****************************************/
   /* Canales y Programaciones
   /*************************************** */
   {
      path:"/programacion-canales",
      component:() => import('components/Views.vue'),
      children:[

          {
            path:'',
            name:'canal.list',
            component:() => import('views/programaciones/canales.vue'),
            meta:{
               pageTitle:'Canales',
               resource:'Canales',
               action:'read',
               breadcrumb:[
                  {text:'Listado',active:true}
               ]
            }
         },

         {
            path:':canalId/programaciones',
            props:true,
            name:'programacion.list',
            component:() => import('views/programaciones/list.vue'),
            meta:{
               pageTitle:'Programaciones',
               resource:'Programaciones',
               action:'read',
               navActiveLink:'canal.list',
               breadcrumb:[
                  {text:'Canales',to:{name:'canal.list'},active:false},
                  {text:'Programaciones',active:true}
               ]
            }
         },

         {
            path:':canalId/programacion/:programacionId/region/:regionId/contenidos',
            props:true,
            name:'programacion_region.contenidos',
            component:() => import('views/programaciones/region/contenidos.vue'),
            meta:{
               pageTitle:'Contenidos de la programación',
               resource:'Contenidos',
               action:'read',
               navActiveLink:'canal.list',
               breadcrumb:[
                  {text:'Canales',to:{name:'canal.list'},active:false},
                  {text:'Programaciones',to:{name:'programacion.list'},active:false},
                  {text:'Contenidos',active:true}
               ]
            }

         }
      ]
   }, 

    /*****************************************/
   /* Medios
   /*************************************** */
   {
      path:'/medios',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'medios.list',
            component:() => import('views/medios/list.vue'),
            meta:{
               pageTitle:'Medios de comunicación',
               resource:'Medios de comunicación',
               action:'read',
               breadcrumb:[
                  {text:'Listado',active:true}
               ]
            }
         },
         {
            path:'create',
            name:'medios.create',
            component:() => import('views/medios/create.vue'),
            meta:{
               pageTitle:'Crear Medio de comunicación',
               resource:'Medios de comunicación',
               action:'write',
               navActiveLink:'medios.list',
               breadcrumb:[
                  {text:'Listado',to:{name:'medios.list'},active:false},
                  {text:'Crear',active:true}
               ]
            }
         },
         {
            path:':id/edit',
            name:'medios.edit',
            props:true,
            component:() => import('views/medios/edit.vue'),
            beforeEnter:(to,from,next) => {
               store.dispatch('medio/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },
            meta:{
               pageTitle:'Editar Medio de comunicación',
               resource:'Medios de comunicación',
               action:'update',
               navActiveLink:'medios.list',
               breadcrumb:[
                  {text:'Listado',to:{name:'medios.list'},active:false},
                  {text:'Editar',active:true}
               ]
            }
         }
      ]
   },

    /*****************************************/
   /* Reproductores
   /*************************************** */
   {
      path:'/reproductors',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'reproductor.list',
            component:() => import('views/reproductores/list.vue'),
            meta:{
               pageTitle:'Reproductores para canales internos',
               resource:'Reproductores',
               action:'read',
               breadcrumb:[
                  {text:'Listado',active:true}
               ]
            }
         },
         {
            path:'create',
            name:'reproductor.create',
            component:() => import('views/reproductores/create.vue'),
            meta:{
               pageTitle:'Registrar Reproductor',
               resource:'Reproductores',
               action:'write',
               navActiveLink:'reproductor.list',
               breadcrumb:[
                  {text:'Listado',active:false,to:{name:'reproductor.list'}},
                  {text:'Creando',active:true}
               ]
            }
         },
          {
            path:':id/edit',
            name:'reproductor.edit',
            props:true,
            beforeEnter: (to,from,next) => {
                  store.dispatch('reproductor/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  }) 
            },

            component:() => import('views/reproductores/edit.vue'),
            meta:{
               pageTitle:'Editar registro',
               resource:'Reproductores',
               action:'update',
               navActiveLink:'reproductor.list',
               breadcrumb:[
                  {text:'Listado',active:false,to:{name:'reproductor.list'}},
                  {text:'Editando',active:true}
               ]
            }
         }
      ]

   },

    /*****************************************/
   /* Reporte
   /*************************************** */

   {
      path:'/reportes/programacion-medios',
      component: () => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'reporte.programacion',
            component:() => import('views/reportes/programaciones/list.vue'),
            meta:{
               pageTitle:'Programaciones de medios de comunicación',
               resource:'Reporte de programación',
               action:'read',
               breadcrumb:[
                  {text:'Listado',active:true}
               ]
            }
         }
      ]
   },

   {
      path:'/reportes/odts',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'',
            component:() => import('views/reportes/odts/list.vue'),
            name:'reporte.odts',
            meta:{
               pageTitle:"Reporte de ODT'S",
               resource:'Reporte de ODTS',
               action:'read',
               breadcrumb:[
                  {text:'Listado',active:true}
               ]
            }
         }
      ]
   },
   

   /*****************************************/
   /* Tickets Soporte
   /*************************************** */
   {
      path:'/tickets',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'tickets.list',
            component:() => import('views/soporte/list.vue'),
            meta:{
               pageTitle:'Tickets de soporte',
               resource:'Tickets',
               action:'read'
            }
         },
         {
            path:'create',
            name:'ticket.create',
            component:() => import('views/soporte/create.vue'),
            meta:{
               pageTitle:'Crear ticket de soporte',
               resource:'Tickets',
               action:'write',
               navActiveLink:'tickets.list',
               breadcrumb:[
                  {text:'Listado',to:{name:'tickets.list'},active:false},
                  {text:'Crear',active:true}
               ]
            }
         }
      ]
   },

   
   /*****************************************/
   /* Pagina de términos y condiciones
   /*************************************** */
   {
      path:'/terms-and-conditions',
      name:'terminos.condiciones',
      component:() => import('views/terminos/index.vue'),
      meta:{
         resource:'Términos y condiciones',
         action:'read',
         pageTitle:"Términos y condiciones",
        
      }
   },


   /*****************************************/
   /* PAGINA DE Mantenimiento
   /*************************************** */
   {
      path:'/mantenimiento',
      name:'show.mantenimiento',
      component:() => import('views/mantenimiento/index.vue'),
      meta:{
         layout:'full',
      }
   },
   
]
