export default {
  namespaced:true,

  state:() => ({
    plan:{
      id:null,
      nombre:null,
      precio:0,
      descripcion:null,
      odts:0,
      acumulable:false,
      vencimiento:30, // 15,30,60,90,... 
      medio_id:null,
      medio:null
    },

    planes:[],
    
  }),

  getters:{
    draft:(state) => clone(state.plan)
  },


  mutations:{
    setPlan(state,plan){
      state.plan = plan
    },

    setPlanes(state,planes){
      state.planes = planes
    },

    clear(state){
      state.plan = {
        id:null,
        nombre:null,
        precio:0,
        odts:0,
        descripcion:null,
        acumulable:false,
        vencimiento:30, // 15,30,60,90,... 
        medio_id:null,
        medio:null
      }
    }
  },

  actions:{
    fetch({commit},plan_id){
        return new Promise((resolve, reject) => {
          axios.get(`/api/plans/${plan_id}/fetch-data`).then(({data}) => {
            if(data.id){
              commit('setPlan',data)
            }
            resolve(data)
          }).catch(e => reject(e))

        })
    },

    fetchData({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/plans/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    getPlanes({commit}){

      return new Promise((resolve, reject) => {
        axios.get(`/api/plans/get-data`).then(({data}) => {
          commit('setPlanes',data)
          resolve(data)
        }).catch(e => reject(e))
      })

    },


    save({commit,state},datos){
      
      return new Promise((resolve, reject) => {
        
        if(datos.id){
          axios.put(`/api/plans/${datos.id}`,datos).then(({data}) => {
           if(data.result){
              commit('setPlan',data.plan)
           }
            resolve(data)
          }).catch(e => reject(e))
        }else{
          axios.post('/api/plans',datos).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
        }

      });

     
    },

    destroy({commit,state},plan_id){
      
      return new Promise((resolve, reject) => {
        axios.delete(`/api/plans/${plan_id}`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      });

    }
  }
  
}