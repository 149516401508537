import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

// Import Modulos

import usuario from './modules/usuario';
import rol from './modules/configuracion/rol.js';
import permiso from './modules/configuracion/permiso.js';
// 
import notificacion from './modules/notificaciones.js';
import sistema from './modules/sistema.js'
import idioma from './modules/idioma.js'

// movimiento
import movimiento from './modules/movimiento.js'
import cuenta from './modules/cuenta.js'

// Tipo de Clientes

import tipo_cliente from './modules/tipo_cliente.js';

// Cliente
import cliente from './modules/cliente.js'

// Areas
import area from './modules/area.js'

// Responsable

import responsable from './modules/responsable.js'

// Pagos
import recarga from './modules/recarga.js'

// Banco
import banco_audiovisual from './modules/BancoAudiovisual.js'

// Carpeta
import carpeta from './modules/carpeta.js'


// Archivo
import archivo from './modules/archivo.js'

// Region

import region from './modules/region.js'

// diplay
import display from './modules/display.js'


// Categoria de producto
import categoria from './modules/categoria.js'

// Producto
import producto from './modules/producto.js';

// Divisa
import divisa from './modules/divisa.js';


// carrito Compra

import carritoCompra from './modules/carritoCompra.js'

// compra 
import compra from './modules/compra.js'


// iva 
import iva from './modules/iva.js'

// Servicios
import servicio from './modules/servicio.js'

// Contrato 
import contrato from './modules/contrato.js';

// canal
import canal from './modules/canal.js';

// widgets
import widget from './modules/widget.js';

// programacion
import programacion from './modules/programacion.js';

// solicitud
import solicitud from './modules/solicitud.js';

// contenido
import contenido from './modules/contenido.js';


// ticket 
import ticket from './modules/ticket.js'

// Dashboard
import dashboard from './modules/dashboard/dashboard.js';

// planes de transmision

import plan from './modules/plan.js';

// Medios de comunicación
import medio from './modules/medio.js';

// Reproductores
import reproductor from './modules/reproductor.js';


// panel
import panel from './modules/configuracion/panel.js';

// Entrega
import entrega from './modules/entrega.js';

Vue.use(Vuex)
export default new Vuex.Store({

	state: {
	  errors: {},
		loading: false,
    token:null,
    canales:[],
    auth:{
      message:null
    }
	},

	mutations: {

		cerrarApp(state) {
			state.usuario = null
		},

		toggleLoading(state,bol = null) {
			state.loading = (bol != null) ? bol : !state.loading
		},

		setError(state, data) {

      
      if (typeof data === 'string'){
        state.errors.message = data;
      }else{
        state.errors = data;
      }

		},

		clearErrors(state) {

			state.errors = {
				carta: {}
			}

		},
    
    setAuthMessage(state,data){
      state.auth.message = data;

    },

    setToken(state,token){
      state.token = token
    },

    pushCanal(state,canal){
      state.canales.push(canal)
    }


	},

	actions: {
     async cerrarSesion({ state, commit }) {
      return await axios.get('/api/auth/logout',null,{
        headers: {
          'WWW-Authenticate': 'Bearer', 'Authorization': (state.token) ? state.token : localStorage.getItem('accessToken')
        }
      });
    }
	},

  
  modules: {
    app,
    appConfig,
    verticalMenu,
    usuario,
    rol,
    notificacion,
    permiso,
    sistema,
    idioma,
    movimiento,
    cuenta,
    tipo_cliente,
    cliente,
    area,
    responsable,
    recarga,
    banco_audiovisual,
    carpeta,
    archivo,
    region,
    display,
    categoria,
    producto,
    divisa,
    carritoCompra,
    compra,
    iva,
    servicio,
    contrato,
    canal,
    widget,
    programacion,
    solicitud,
    contenido,
    ticket,
    dashboard,
    plan,
    medio,
    reproductor,
    panel,
    entrega

  },
  strict:false,

})
