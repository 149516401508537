import { headers } from "@core/utils/utils"
export default {
  namespaced:true,
  state:() =>({
    
    carrito:{
      id:null,
      cliente_id:null,
      cliente:null,
      total:0,
      productos:[],
    },

    carritos:[],

  }),

  getters:{
    draft:(state) => clone(state.carrito),
  },



  mutations:{

    clear(state){
      state.carrito = {
        id:null,
        cliente_id:null,
        cliente:null,
        total:0,
        productos:[],
      }
    },


    setCarritos(state,carritos){
      if(carritos){
        state.carritos = carritos
      }
    },


    setCarrito(state,carrito){
      if( carrito){
        state.carrito = carrito
      }
    },
    update(state,carrito){
      if(carrito){
        state.carrito = carrito
      }
    },

    agregarProducto(state,producto){
      if(producto){
        state.carrito.productos.push(producto)
      }
    },

    setTotal(state,total){
      state.carrito.total = total
    },
    
    removerProducto(state,producto_id){
      state.carrito.productos = state.carrito.productos.filter(p => p.id !== producto_id)
    }

  },


  actions:{

    fetch({commit},carrito_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/carrito-compras/${carrito_id}/fetch-data`).then(({data}) => {
          commit('setCarrito',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchDataProductos({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/carrito-compras/fetch-data-productos`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e));
      })
    },



    fetchCarritoCliente({commit},cliente_id){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/carrito-compras/clientes/${cliente_id}/fetch-data`).then(({data}) => {
          commit('setCarrito',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    guardar({commit,dispatch},datos){
      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/carritos/${datos.id}`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
        }else{
          axios.post(`/api/carritos`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
        }
      })
    },


    addProducto({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/carrito-compras/add-producto`,datos).then(({data}) => {
          if(data.result){
            commit('setCarrito',data.carrito)
          }
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    removerProducto({commit},{carrito_id,producto_id}){
       return new Promise((resolve, reject) => {
        axios.get(`/api/carrito-compras/${carrito_id}/producto/${producto_id}/remove`).then(({data}) => {
          if(data.result){
            // commit('setCarrito',data.carrito)
          }
          resolve(data)
        }).catch(e => reject(e))
      })
    },
    

    eliminar({state,commi},carrito_id){
        return new Promise((resolve, reject) => {
          axios.delete(`/api/carritos/${carrito_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
        })
    }

    

  }
} 