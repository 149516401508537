
import { headers } from "../../@core/utils/utils";
export default {
  namespaced:true,

  state:() => ({
    display:{
      id:null,
      width:1920,
      height:1080,
      nombre:'',
      imagen:null,
      imagen_url:null,
      hash_imagen:null,
      horientacion:1, // 1 => Horizontal, 2 => Vertical
      regiones:[],
    },

    displays:[],

    horientacionOptions:[
      {text:'Horizontal',value:1},
      {text:'Vertical',value:2},
    ]
  }),

  getters:{
    draft:(state) => clone(state.display)
  },

  mutations:{
    clear(state){
      state.display = {
        id:null,
        width:1142,
        height:585,
        nombre:'',
        imagen:null,
        imagen_url:null,
        hash_imagen:null,
        horientacion:1, // 1 => Horizontal, 2 => Vertical
        regiones:[],
      }
    },

    setDisplay:(state,display) => state.display = display,
    setDisplays:(state,displays) => state.displays = displays,
    updateDegion:(state,display) => {
      let r = state.displays.findIndex(v => v.id === display.id);
      if(r){
        state.displays[r] = display
        if(state.display.id === display.id){
          state.display = display
        }
      }

    },

    pushDisplay(state,display){
      state.displays.push(display)
    },

    removeDisplay(state,r_id) {
        let r = state.displays.findIndex(v => v.id === r_id)
        if(r != -1){
          state.displays.splice(r,1)
        }
    },

    setHContainer(state,h) {

      if(h){
        state.display.height = h
      }

    },

    setWContainer(state,w) {
      if(w){
        state.display.width = w
      }
    }



  },


  actions:{
    fetch({state,commit},display_id){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/displays/${display_id}/fetch-data`).then(({data}) => {
          commit('setDisplay',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },

    fetchData({state,commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/displays/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    guardar({state,commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/displays/${datos.id}`,datos).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
        }else{
          axios.post(`/api/displays`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
        }
      })

    },

    eliminar(_,display_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/displays/${display_id}`).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
      })

    },

    fetchDataSelect({commit},{horientacion}){
      return new Promise((resolve, reject) => {
        axios.post(`/api/displays/fetch-select`,{horientacion}).then(({data}) => {
          commit('setDisplays',data)
          resolve(data)
        }).catch(e => reject(e))
      })
    }


  }
}