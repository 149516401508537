export default{
  namespaced:true,
  state(){
    return{
      compra:{
        id:null,
        cliente_id:null,
        factura_id:null,
        estatus:1,
        divisa_id:null,
        con_factura:false,
        iva:0,
        direccion:{
          nombre:'',
          apellido:'',
          telefono:null,
          direccion:'',
          estado_id:null,
          ciudad_id:null,
          codigo_postal:null
        },
        sub_total:0,
        total:0,
        monto_envio:0,
        paypal_id:null,
        cliente:null,
        productos:[]
      },
      compras:[]
    }
   
  },

  getters:{

    draft(state){
      return clone(state.compra)
    }

  },

  mutations:{
      clear(state){
         state.compra = {
            id:null,
            cliente_id:null,
            factura_id:null,
            estatus:1,
            divisa_id:null,
            con_factura:false,
            iva:0,
            direccion:{
              nombre:'',
              apellido:'',
              telefono:null,
              direccion:'',
              estado_id:null,
              ciudad_id:null,
              codigo_postal:null
            },
            sub_total:0,
            total:0,
            cliente:null,
            productos:[],
            monto_envio:0,
            paypal_id:null,
         }
      },


      setCompras(state, compras) {
         state.compras = compras
      },

      setCompra(state, compra) {
        if(compra){
         state.compra = compra
        }
      },

      update(state, compra) {

         let i = state.compras.findIndex(val => val.id === compra.id)
         if (i != -1) {
            state.compras[i] = compra
         }  

          if (state.compra == compra.id) {
              state.compra = compra
          }
      }
  },

  actions:{

      fetch({commit},compra_id){
        return new Promise((resolve, reject) => {
          
          axios.get(`/api/compras/${compra_id}/fetch-data`).then(({data}) => {
            commit('setCompra',data)
            resolve(data)
          }).catch(e => reject(e))

        })
      },


      fetchData({ commit }, datos) {

         return new Promise((resolve, reject) => {

            axios.post('/api/compras/fetch-data', datos).then(({ data }) => {
               commit('setCompras', data.compras)
               resolve(data)
            }).catch(e => reject(e))

         })
      },


      guardar({ commit }, datos) {

         return new Promise((resolve, reject) => {
            if (datos.id) {
               axios.put(`/api/compras/${datos.id}`, datos).then(({ data }) => {
                  if (data.result) {
                     commit('update', data.compra)
                  }
                  resolve(data)
               }).catch(e => reject(e))

            } else {

               axios.post(`/api/compras`, datos).then(({ data }) => {
                  resolve(data)
               }).catch(e => reject(e))
            }
         })


      },


      eliminar({ commit }, compra_id) {

         return new Promise((resolve, reject) => {
            axios.delete(`/api/compras/${compra_id}`).then(({ data }) => {
               resolve(data)
            }).catch(e => reject(e))

         })
      }
      
   }
  
}
