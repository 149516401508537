export default{
   namespaced:true,

   state:() => ({

      tipo:{
         id:null,
         nombre:'',
         icono:null,
         descripcion:''
      },

      tipos:[],

     

   }),


   getters:{
      draft(state){
         return clone(state.tipo)
      },
     
   },

   mutations:{
      clear(state){
         state.tipo = {
            id:null,
            nombre:'',
            icono:null,
            descripcion:''
         }
      },


      setTipo(state,tipo){
         state.tipo = tipo
      },

      update(state,tipo){
         const i = state.tipos.findIndex(val => val.id === tipo.id)

         if(i !=-1){
            state.tipos[i] = tipo
         }

         if(state.tipo.id === tipo.id){
            state.tipo = tipo
         }
      },

      push(state,tipo){
         state.tipos.push(tipo)

      },

      put(state,tipo_id){
         state.tipos.splice(
            state.tipos.findIndex(val => val.id === tipo_id),
            1
         )
      },

      capturar(state,tipo_id){
         const tipo  = state.tipos.find(val => val.id === tipo_id)

         if(tipo != undefined){
            state.tipo = tipo
         }
      },

      setTipos(state,tipos){
         state.tipos = tipos
      }
   },

   actions:{

      
      fetchData({state,commit},datos){

         return new Promise((resolve, reject) => {
            axios.post(`/api/tipo-clientes/fetch-data`,datos).then(({data}) => {
               commit('setTipos',data.items)
               resolve(data)
            }).catch(e => reject(e))

         })

      },

      fetch({state,commit},tipo_id){

         return new Promise((resolve, reject) => {
            axios.get(`/api/tipo-clientes/${tipo_id}/fetch-data`).then(({data}) => {
               commit('setTipo',data)
               resolve(data)
            }).catch(e => reject(e))

         })
      },

      guardar({commit},datos){

         let formData = new FormData();
       
         Object.keys(datos).forEach(k => {
            formData.append(k,datos[k])
         });

         let headers = {
            ContentType:'multipart/form-data'
         }

         return new Promise((resolve, reject) => {
            if (datos.id) {
               formData.append('_method',"PUT");

               axios.post(`/api/tipo-clientes/${datos.id}`,formData,{headers}).then(({data}) => {
                  resolve(data)
               }).catch(e => reject(e))

            }else{

               axios.post(`/api/tipo-clientes`,formData,{headers}).then(({data}) => {
                
                  resolve(data)

               }).catch(e => reject(e))

            }
         })
       
      },

      getListSelect({commit}){
         return new Promise((resolve, reject) => {
            axios.get(`/api/tipo-clientes/get/list-select`).then(({data}) => resolve(data)).catch(e => reject(e));
         })
      },

      eliminar({ commit }, tipo_id) {
         return new Promise((resolve, reject) => {
            axios.delete(`/api/tipo-clientes/${tipo_id}`).then(({ data }) => {
               resolve(data)
            }).catch(e => reject(e))

         })

      }

   },







}