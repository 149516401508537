import { headers } from "@core/utils/utils"
export default {
  namespaced:true,
  state:() =>({
    
    banco:{
      id:null,
      cliente_id:null,
      nombre_disk:'',
      ocupado:0,
      tamano_disk:1,// 1GB, 2GB, 3GB, 4GB
      cliente:null,
      carpetas:[]
    },

    bancos:[],
    directorio:[]

  }),

  getters:{
    draft:(state) => clone(state.banco),
  },



  mutations:{

    clear(state){
      state.banco = {
        id:null,
        cliente_id:null,
        nombre_disk:'',
        ocupado:0,
        tamano_disk:1,// 1GB, 2GB, 3GB, 4GB
        cliente:null,
        carpetas:[]
      }
    },


    setBancos(state,bancos){
      if(bancos){
        state.bancos = bancos
      }
    },


    setBanco(state,banco){
      if( banco){
        state.banco = banco
      }
    },
    update(state,banco){
      if(banco){
        state.banco = banco
      }
    },

    setDirectorio:(state,datos) => {
      state.directorio = datos
    }

  },


  actions:{

    fetch({commit},banco_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/bancos/${banco_id}/fetch-data`).then(({data}) => {
          commit('setBanco',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/bancos/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    resizeDisk({commit,dispatch},datos){
      return new Promise((resolve, reject) => {
        axios.put(`/api/bancos/${datos.id}/change-disk`,datos).then(({data}) => {
          if(data.result){
            commit('update',data.banco)
          }
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    fetchDirectory({commit,dispatch},{disk,directory}){

      return new Promise((resolve, reject) => {
        
        axios.post(`/api/bancos/fetch-directory`,{disk_name:disk,directory}).then(({data}) => {
          commit('setDirectorio',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchPorDisk({commit},disk){
      return new Promise((resolve, reject) => {
        
        axios.post(`/api/bancos/fetch-por-disk`,{disk}).then(({data}) =>{
          commit('setBanco',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    }

  }
}