
export default{

	namespaced:true,

	state(){
		return{

			permiso:{
				id      : null,
				nombre  : '',
				descripcion:'',
				panels:[],
				actions_disabled:[],
			},
			
			permisos:[],
		}
	},


	mutations:{

		setPermiso(state,permiso){
			state.permiso = permiso

		},

		setPermisos(state,permisos){

			state.permisos = permisos
		
		},


		clearPermiso(state){

			state.permiso = {
				id: null,
				nombre: '',
				descripcion:'',
				panels:[],
				actions_disabled:[],
			}
		
		},

		capturarPermiso(state,id_permiso){
			state.permiso = state.permisos.find((permiso) => permiso.id === id_permiso);
		},


		putPermiso(state,id_permiso){

			state.permisos.splice(state.permisos.findIndex((permiso) => permiso.id == id_permiso),1);
		},

		pushPermiso(state,permiso){
			var permis = state.permisos.find((permis) => permis.id == permiso.id);

			if(permis){
				state.permisos.forEach((permis,i ) => {
					if(permis.id == permiso.id){
						state.permisos[i] = permiso
					}
				})
			}else{

				state.permisos.push(permiso);
			}

		},

	},

	getters:{

		draft(state){
			return clone(state.permiso);
		},


		getPermissionUser:(state) => {
			
			return ({rol,permisos}) => {	
				return permisos.map(val => {
					let actions = null;

					if (rol.permisos) {
						const rolPermissions = rol.permissions.find(va => va.id == val.id);
						actions = rolPermissions ? JSON.parse(typeof rolPermissions.pivot.actions == 'string' ? rolPermissions.pivot.actions : JSON.stringify(rolPermissions.pivot.actions)) : null;
					}

						
					return {	
						module: val.nombre,
						descripcion:val.descripcion ?? 'S/D',
						actions_disabled:val.actions_disabled,
						read: actions  && typeof actions === 'object' ? actions.find(v => v == 'read') ? true : false : false,
						write: actions  && typeof actions === 'object' ? actions.find(v => v == 'write') ? true : false : false,
						update: actions  && typeof actions === 'object' ? actions.find(v => v == 'update') ? true : false : false,
						delete: actions  && typeof actions === 'object' ? actions.find(v => v == 'delete') ? true : false : false,
					}
				})


			}

		},

		getPermissionUsuario:(state) => {
			
			return ({permissions,permisos}) => {	
				return permisos.map(val => {
					let actions = null;

					if (permissions.length) {
						const rolPermissions = permissions.find(va => va.id == val.id);
						if(rolPermissions != undefined){
							return rolPermissions;
						}
					}

						
					return {
						id:val.id,
						module: val.nombre,
						descripcion:val.descripcion ?? 'S/D',
						actions_disabled:val.actions_disabled,
						read: actions  && typeof actions === 'object' ? actions.find(v => v == 'read') ? true : false : false,
						write: actions  && typeof actions === 'object' ? actions.find(v => v == 'write') ? true : false : false,
						update: actions  && typeof actions === 'object' ? actions.find(v => v == 'update') ? true : false : false,
						delete: actions  && typeof actions === 'object' ? actions.find(v => v == 'delete') ? true : false : false,
					}
				})


			}

		},


		




	},

	actions:{
		
		cargarPermisos({state,commit}){
			
			return new Promise((resolve, reject) => {
					axios.get('/api/cargar/permisos').then(({data}) => {
					commit('setPermisos',data);
					resolve(data)
				}).catch(e => {
					console.log(e);
					reject(e)
				})
			})
			


		},

		guardarPermiso({state,commit},datos){

			return new Promise((resolve, reject) => {
				if (datos.id) {
					
					axios.put(`/api/permisos/${datos.id}`, datos).then(({data}) => {
						resolve(data)
					}).catch(e => reject(e))

				}else{
					
					axios.post('/api/permisos', datos).then(({data}) => {
						resolve(data)
					}).catch(e => reject(e))

				}
			})
		
		},

		async eliminarPermiso({state,commit},id_permiso){

			return new Promise((resolve, reject) => {
				commit('toggleLoading',null,{root:true})
				axios.delete('/api/permisos/' + id_permiso).then(({data}) => {
					if(data.result){
						commit('putPermiso',id_permiso)
					}

					resolve(data)
				}).catch(e => reject(e))
				.then(() => {
					commit('toggleLoading', null, { root: true })
				})

			})
		},

		fetch({commit},permiso_id){

			return new Promise((resolve, reject) => {
				
				axios.get(`/api/permisos/${permiso_id}/fetch-data`).then(({data}) => {
					commit('setPermiso',data)
					resolve(data)
				}).catch(e => {
					reject(e)
				})

			})
		},

		fetchData({ commit }, datos) {

			return new Promise((resolve, reject) => {
				axios.post(`/api/permisos/fetch-data`, datos).then(({ data }) => {
					commit('setPermisos', data.permisos)
					resolve(data)
				}).catch(e  => reject(e))
			});

		},


		getPermiso({ commit }, id_permiso){

			return new Promise((resolve, reject) => {
				
				axios.get(`/api/permisos/${id_permiso}/get`).then(({ data }) => {
					commit('pushPermiso',data)
					resolve(data)
				}).catch(e => reject(e))
			})

		},

		getPermisosPorPanel({commit},panel) {
			return new Promise((resolve, reject) => {
				axios.get(`/api/permisos/panel/${panel}`).then(({data}) => {
					resolve(data)
				}).catch(e => reject(e))
			})
		}

	}

}