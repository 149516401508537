export default {

   namespaced:true,

   state:() => ({

      contrato:{
        id          : null,
        fecha_inicio: null,
        tipo_cobro  : 1,
        monto       : 0,
        compra_id   : null,
        compra      : null,
        cliente_id  : null,
        cliente     : null,
        proximo_cobro:null

      },

      contratos:[]

   }),


   getters:{
      draft(state){
         return clone(state.contrato)
      },

   },



   mutations:{

      clear(state){
         state.contrato = {
            id          : null,
            fecha_inicio: null,
            tipo_cobro  : 1,
            monto       : 0,
            compra_id   : null,
            compra      : null,
            cliente_id  : null,
            cliente     : null,
            proximo_cobro:null
         }
      },


      setContratos(state,data){

         if(data.length){
            state.contratos = data
         }
      },

      setContrato(state,contrato){
         state.contrato = contrato
      },


      push(state,contrato){
         
         if(contrato.id){
            state.contratos.push(contrato)
         }

      },


      put(state,contrato_id){
         
         state.contratos.splice(
            state.contratos.findIndex(val => val.id === contrato_id),
            1
         );
      },

      update(state,contrato){
         let i = state.contratos.findIndex(val => val.id === contrato.id)

         if(i != -1){  
            state.contratos[i] = contrato
            if(state.contrato.id === contrato.id){
               state.contrato = contrato               
            }
         }

      }


   },


   actions:{


 

      fetchData({commit},datos){
         return new Promise((resolve, reject) => {
            axios.post('/api/contratos/fetch-data',datos).then(({data}) => {
               commit('setContratos',data.contratos);
               resolve(data);
            }).catch(e => reject(e))

         });


      },


      fetch({commit},contrato_id){

         return new Promise((resolve, reject) => {

            axios.get(`/api/contratos/${contrato_id}/fetch-data`).then(({data}) => {
               commit('setContrato',data)
               resolve(data);

            }).catch(e => reject(e))

         })
      },

      guardar({state,commit},datos){
         return new Promise((resolve, reject) => {
            if (state.contrato.id) {

               axios.put(`/api/contratos/${datos.id}`, datos).then(({ data }) => {
                  commit('update', data.contrato)
                  resolve(data)
               }).catch(e => reject(e))


            } else {

               axios.post(`/api/contratos`, datos).then(({ data }) => {

                  commit('push', data.contrato)
                  resolve(data)

               }).catch(e => reject(e))

            }
         })

        


      },

      eliminar({commit},contrato_id){

         return new Promise((resolve, reject) => {
            
            axios.delete(`/api/contratos/${contrato_id}`).then(({data}) => {

               if(data.result){
                  commit('put',contrato_id)
               }

               resolve(data)
            }).catch(e => {
               reject(e)
            })

         })

      },
   }
}
