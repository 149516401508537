import { headers } from "@core/utils/utils"

export default {
  namespaced:true,
  state:() =>({
    solicitud:{
      id:null,
      responsable_id:null,
      aprobado:false,
      fecha_aprobacion:null,
      responsable:null,
      contenidos:[],
      estatus:0, // 0:pendiente, 1:aprobado, 2:rechazado
      observacion_rechazo:'',
      tipo_solicitud: 0, // 0 : agregar contenido, 1 : Reorganizar contenido , 2 : Eliminar contenido
    },
    solicitudes:[],
    optionsEstatus:['Pendiente','Aprobado','Rechazado']
  }),

  getters:{
    draft:(state) => clone(state.solicitud),
    optionsEstatus:(state) => state.optionsEstatus.map((v,i) => ({text:v,value:i})),
    getVariantEstatus:(state) => (estatus) => {
      switch(estatus){
        case 0:
          return 'warning'
        case 1:
          return 'success'
        case 2:
          return 'danger'
      }
    },
    getEstatus:(state) => (estatus = 0) => {
      return state.optionsEstatus[estatus];
    }

  },



  mutations:{

    clear(state){
      state.solicitud = {
        id:null,
        responsable_id:null,
        aprobado:false,
        fecha_aprobacion:null,
        responsable:null,
        contenidos:[],
        estatus:0, // 0:pendiente, 1:aprobado, 2:rechazado
        observacion_rechazo:'',
        tipo_solicitud: 0, // 0 : agregar contenido, 1 : Reorganizar contenido , 2 : Eliminar contenido

      }
    },


    setSolicitudes(state,solicituds){
      if(solicituds){
        state.solicitudes = solicituds
      }
    },


    setSolicitud(state,solicitud){
      if( solicitud){
        state.solicitud = solicitud
      }
    },

    update(state,solicitud){
      let i = state.solicitudes.findIndex(c => c.id == solicitud.id)

      if(i != -1){
        state.solicitudes[i] = solicitud
      }

      if(state.solicitud.id == solicitud.id){
        state.solicitud = solicitud
      }
    }


  },


  actions:{

    fetch({commit},solicitud_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/solicituds/${solicitud_id}/fetch-data`).then(({data}) => {
          commit('setSolicitud',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/solicitudes/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    crear({state,commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/solicituds`,datos).then(({data}) => {
          resolve(data);
        }).catch(e => reject(e))
      })
    },
    
    guardar({commit},datos){

      let formData = new FormData()

      Object.keys(datos).forEach(key => {
        formData.append(key,datos[key])
      })

      return new Promise((resolve, reject) => {
        if(datos.id){
          formData.append('_method','PUT')
          axios.post(`/api/solicituds/${datos.id}`,formData,{headers}).then(({data}) => {
            if(data.result){
              commit('update',data.solicitud)
            }
            resolve(data)
          }).catch(e => reject(e))
        }else{
          axios.post(`/api/solicituds`,formData,{headers}).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
        }
         
      })
    },

    eliminar({state,commit},solicitud_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/solicituds/${solicitud_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    toggleEstatus({state,commit},datos){
      return new Promise((resolve, reject) => {
          axios.put(`/api/solicituds/${datos.id}/toggle-estatus`,datos).then(({data}) => {
            if(data.result){
              commit('update',data.solicitud)
            }
            resolve(data)
          }).catch(e => reject(e))
      })
    },

    solicitudEliminacion({commit,state},{responsable_id,contenidos,programacion_id}){
      return new Promise((resolve, reject) => {
        
        axios.post(`/api/solicituds/${responsable_id}/eliminar-contenido`,{contenidos,programacion_id}).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })
    },

    reordenarContenido({commit,state},{programacion_id,responsable_id,contenidos}){
      return new Promise((resolve, reject) => {
        axios.post(`/api/solicituds/${responsable_id}/reordenar-contenido`,{programacion_id,contenidos}).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    fetchContenidos({commit},solicitud_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/solicituds/${solicitud_id}/fetch-contenidos`).then(({data}) => {
          commit('contenido/setContenidos',data.contenidos,{root:true})
          resolve(data)
        }).catch(e => reject(e))
      })
    }

  }

}