import Vue from 'vue'
import TheMask from 'vue-the-mask';
import VueCurrencyFilter from 'vue-currency-filter'
import VueMask from 'v-mask'
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
import Highcharts3d from 'highcharts/highcharts-3d'
import Maps from "highcharts/modules/map";

import ElementUI from 'element-ui';
import '@core/scss/base/element.scss';
import locale from 'element-ui/lib/locale/lang/es';


import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import esES from '@kangc/v-md-editor/lib/lang/es-ES';


import FontIconBadge from 'components/FontIconBadge.vue';
Vue.component('font-icon-badge', FontIconBadge);
// Prism
import Prism from 'prismjs';
// highlight code
import 'prismjs/components/prism-json';

VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});
VueMarkdownEditor.lang.use('esES', esES);

Vue.use(VueMarkdownEditor);


Vue.use(ElementUI,{locale});


Vue.use(TheMask);

Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
});


Vue.use(VueMask);


Maps(Highcharts);

import highchartsMore from "highcharts/highcharts-more.js"

highchartsMore(Highcharts);


Highcharts3d(Highcharts);
// Opcionalmente, también puedes importar módulos adicionales de Highcharts, como exporting o accessibility
import exportingInit from 'highcharts/modules/exporting';
exportingInit(Highcharts);

import SolidGaugeInit from 'highcharts/modules/solid-gauge'
SolidGaugeInit(Highcharts);

import accessibilityInit from 'highcharts/modules/accessibility';
accessibilityInit(Highcharts);

// Registra Highcharts Vue Wrapper como componente global
Highcharts.setOptions({
   colors:  [
    '#397DAD',
    '#4D616C',
    '#CD960E',
    '#348022',
    '#17B6AA',
    '#5F2626',
    '#00AEFF',
    '#6574cd',
    '#9561e2',
    '#f66d9b',
    '#38c172',
    '#4dc0b5',
    '#6cb2eb',
    '#444444',
    '#00c0ef',
  ],

	chart:{
    backgroundColor: {
          linearGradient: [0, 0, 500, 500],
          stops: [
              [0, 'rgba(255, 255, 255, 0)'],
              [1, 'rgba(240, 240, 255, 0)']
          ]
      },

		// borderWidth:2,
    borderColor:{
      pattern:{
        backgroundColor:'rgb(255,255,255)',
        opacity:0,
      }
    },
		style:{
			"fontFamily":'Myriad Regular Serif Roboto',

		},
		spacingTop:0,

	},
	credits:{
		enabled:true,
		href:import.meta.env.VITE_APP_URL,
		text:"CityFuntastic",
	},
});

Vue.use(HighchartsVue,{
  Highcharts:Highcharts
});


import { library } from '@fortawesome/fontawesome-svg-core'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'


library.add(fas,fab,far)
Vue.component('font-awesome-icon', FontAwesomeIcon)


import GmapVue from 'gmap-vue';

Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyCnsLa_yFH7kb5s5GdIaE_P9aGKLkeVIUQ', //'AIzaSyCNWsVH2kmknm6knGSRKDuzGeMWM1PT6gA',
    libraries: 'places',
  },
  installComponents: true
});

import VCalendar from 'v-calendar';
// import 'v-calendar/src/styles/base.css'

Vue.use(VCalendar);


import moment from 'moment';
moment.locale('es',
   {
    months: 'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split('_'),
    monthsShort: 'ene._feb._mar._abr._may._jun._jul._ago._sep._oct._nov._dic.'.split('_'),
    monthsParseExact: true,
    weekdays: 'domingo_lunes_martes_miércoles_jueves_viernes_sábado'.split('_'),
    weekdaysShort: 'dom._lun._mar._mié._jue._vie._sáb.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sá'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm A',
        LLLL:'dddd D MMMM YYYY HH:mm'
      },
    calendar: {
        sameDay: '[Hoy a] LT',
        nextDay: '[Mañana a] LT',
        nextWeek: 'dddd [a] LT',
        lastDay: '[Ayer a] LT',
        lastWeek: 'dddd [pasado a] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'en %s',
        past: 'hace %s',
        s: 'unos segundos',
        m: 'un minuto',
        mm: '%d minutos',
        h: 'una hora',
        hh: '%d horas',
        d: 'un día',
        dd: '%d días',
        M: 'un mes',
        MM: '%d meses',
        y: 'un año',
        yy: '%d años'
    },
    dayOfMonthOrdinalParse: /\d{1,2}(º|er|a)/,  // Añadido º como opción
    ordinal: function (number) {
        return number + (number === 1 ? 'º' : 'º');  // Modificado para usar º
    },
    meridiemParse: /a.m.|p.m./i,  // Modificado para usar a.m./p.m. en minúscula
    isPM: function (input) {
        return input.toLowerCase().charAt(0) === 'p';  // Adaptado a a.m./p.m.
    },
    meridiem: function (hours, minutes, isLower) {
        return hours < 12 ? 'a.m.' : 'p.m.';  // Modificado para usar a.m./p.m.
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4  // Used to determine first week of the year.
    }
})

window.moment = moment


// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();


