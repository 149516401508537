import { headers } from "@core/utils/utils"

export default {
  namespaced:true,
  state:() =>({
    
    programacion:{
      id:null,
      region_id:null,
      canal_id:null,
      estatus:1 , // 1 => en linea , 2 => con solicitud de cambio, 3 => fuera de linea
      region:null,
      canal:null,
      contenidos:[]
    },
    programacions:[],
    estatuses: ['En linea','Con solicitud de cambio','Sin linea'],
    variants: ['success','warning','danger']
  }),

  getters:{
    draft:(state) => clone(state.programacion),
    getEstatus:(state) => state.estatuses[state.programacion.estatus - 1],

    getVariantEstatus:(state,getters) =>  (estatus) => {
      if(typeof estatus == 'number'){
        return state.variants[estatus - 1]
      }
      return state.variants[state.estatuses.indexOf(estatus)] ?? 'primary'
    }
  },



  mutations:{

    clear(state){
      state.programacion = {
        id:null,
        region_id:null,
        canal_id:null,
        estatus:1 , // 1 => en linea , 2 => con solicitud de cambio, 3 => fuera de linea
        region:null,
        canal:null,
        contenidos:[]

      };
    },

    setProgramaciones(state,programacions){
      if(programacions){
        state.programacions = programacions
      }
    },


    setProgramacion(state,programacion){
      if( programacion){
        state.programacion = programacion
      }
    },

    update(state,programacion){
      let i = state.programacions.findIndex(c => c.id == programacion.id)

      if(i != -1){
        state.programacions[i] = programacion
      }

      if(state.programacion.id == programacion.id){
        state.programacion = programacion
      }
    }


  },


  actions:{

    fetch({commit},programacion_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/programacions/${programacion_id}/fetch-data`).then(({data}) => {
          commit('setProgramacion',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/programacions/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      let formData = new FormData()

      Object.keys(datos).forEach(key => {
        formData.append(key,datos[key])
      })

      return new Promise((resolve, reject) => {
        if(datos.id){
          formData.append('_method','PUT')
          axios.post(`/api/programacions/${datos.id}`,formData,{headers}).then(({data}) => {
            if(data.result){
              commit('update',data.programacion)
            }
            resolve(data)
          }).catch(e => reject(e))
        }else{
          axios.post(`/api/programacions`,formData,{headers}).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
        }
         
      })
    },

    eliminar({state,commit},programacion_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/programacions/${programacion_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    fetchProgramacionCliente({state,commit},{cliente_id,tipo_programacion}){
      return new Promise((resolve, reject) => {
        axios.get(`/api/programacions/cliente/${cliente_id}/tipo-programacion/${tipo_programacion}`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    }

    

  }

}