<template>
  <div class="icon-badge-container">
    <font-awesome-icon :icon="['fas', icon]" :size="size"/>
    <b-badge class="bge" :variant="color" pill :style="{color:colorText}" v-if="showBadge">
      <slot></slot>
    </b-badge>
  </div>
</template>

<script>

import {
  BBadge
} from 'bootstrap-vue';

export default {
  
  components:{
    BBadge,
  },

  props: {
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'lg'
    },
    colorText:{
      type: String,
      default: 'white'
    },
    showBadge:{
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-badge-container {
  position: relative;
  display: inline-block;
  .bge {
    position: absolute !important;
    top: -1rem !important ;
    right: -1.1rem !important;
    font-size: 8pt;
    padding: 5px;
  }
}



</style>