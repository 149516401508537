<template>
  <div id="app" class="h-100" :class="[skinClasses]" ref="refApp">
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
    <reload-prompt />
  </div>
</template>

<script>

import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { watch, onMounted, toRefs, onActivated, computed, provide, ref, onUnmounted } from 'vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { useWindowSize, useCssVar, useNetwork } from '@vueuse/core'
import store from '@/store'
import { useRoute } from 'vue2-helpers/vue-router';
import useAuth from '@core/utils/useAuth.js';
import ReloadPrompt from 'components/ReloadPrompt.vue';

import { useSwipe } from '@vueuse/core';
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout.js';
import { rols_empleados } from '@core/utils/utils'
export default {

  components: {
    LayoutHorizontal: () => import('@/layouts/horizontal/LayoutHorizontal.vue'),
    LayoutVertical: () => import('@/layouts/vertical/LayoutVertical.vue'),
    LayoutFull: () => import('@/layouts/full/LayoutFull.vue'),
    ScrollToTop,
    ReloadPrompt,

  },

  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus

    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')

  },


  setup(props) {

    const route = useRoute();
    const { usuario } = toRefs(store.state.usuario);
    const contentLayoutType = computed(() => store.state.appConfig.layout.type)
    const channelUsers = Echo.channel('usuarios');
    const {cliente} = toRefs(store.state.cliente);
    const isCliente = computed(() => store.getters['usuario/isCliente'])
    const cliente_id = computed(() => cliente.value.id || null)
    const privateCliente = ref(null);
    
    provide('channelUsers',channelUsers)
    provide('privateCliente',privateCliente)


    const crearCanalPrivadoCliente = () => {

      if(isCliente.value){
        Echo.leave(`cliente.${cliente_id.value}`)
        privateCliente.value = Echo.private(`cliente.${cliente_id.value}`)
      }
      
    }

    watch(cliente_id, () => {
      if(cliente_id.value){
        crearCanalPrivadoCliente()
      }
    })

    crearCanalPrivadoCliente()


    const {
      is_loggin
    } = useAuth();

    const layout = computed(() => {

      if (route.meta.layout === 'full') return 'layout-full'

      return `layout-${contentLayoutType.value}`

    })
    

    const cargarSistema = () => {
      store.dispatch('sistema/fetchPublic')
    }

    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout
    const { isOnline } = useNetwork();

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()

    watch(windowWidth, (val) => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

   

    onMounted(() => {
      if (localStorage.getItem('token')) {
        store.commit('usuario/cargarUser', JSON.parse(localStorage.getItem('userData')));

        if (rols_empleados.includes(usuario.value.rol.nombre)) {
          const { cliente:client, responsabilidad } = usuario.value;
          if (client && responsabilidad) {
            store.commit('cliente/setCliente', client)
            store.commit('responsable/setMiResponsabilidad', responsabilidad)
            localStorage.setItem('clienteSeleccionado', client.id)
            localStorage.setItem('disk_name', client.disk)
          }

        }
      }
    })

    watch(isOnline, (val) => {

      if(!val) {
        toast.info("La conexíón ha caido, informalo a tú operadora")
      }

    })

    cargarSistema()

    const refApp = ref(null)
    // Swipe

    const { toggleVerticalMenuActive } = useVerticalLayout();

    const { isSwiping, direction, coordsStart,coordsEnd} = useSwipe(refApp,{
      passive:true,
      disableTextSelect:true
    });
    const anchoSidebar = computed(() => store.state.windowWidth)

    provide('isSwiping',isSwiping)
    provide('directionSwiping', direction)
    provide('coordsStart',coordsStart)
    provide('coordsEnd', coordsEnd)

    // watch(isSwiping, (v) => {
    //   console.log(anchoSidebar.value)
    //   if(v && anchoSidebar.value <= 1200){
    //     console.log('swiper',v)
    //     // toggleVerticalMenuActive();
    //   }
    // })

    return {
      skinClasses,
      enableScrollToTop,
      layout,
      contentLayoutType,
      is_loggin,
      refApp,
      usuario,
      rols_empleados

    }
  },


}
</script>
