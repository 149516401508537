import { headers } from "@core/utils/utils"

export default {
  namespaced:true,
  state:() =>({
    
    widget:{
      id:null,
      nombre:'',
      imagen:null,
      descripcion:'',
      activo:true,
    },
    widgets:[]
  }),

  getters:{
    draft:(state) => clone(state.widget),
  },



  mutations:{

    clear(state){
      state.widget = {
        id:null,
        nombre:'',
        imagen:null,
        descripcion:'',
        activo:true,
      }
    },


    setWidgets(state,widgets){
      if(widgets){
        state.widgets = widgets
      }
    },


    setWidget(state,widget){
      if( widget){
        state.widget = widget
      }
    },

    update(state,widget){
      let i = state.widgets.findIndex(c => c.id == widget.id)

      if(i != -1){
        state.widgets[i] = widget
      }

      if(state.widget.id == widget.id){
        state.widget = widget
      }
    }


  },


  actions:{

    fetch({commit},widget_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/widgets/${widget_id}/fetch-data`).then(({data}) => {
          commit('setWidget',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },

    fetchWidgets({commit}){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/widgets/get-all`).then(({data}) => {
          commit('setWidgets',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },

    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/widgets/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      let formData = new FormData()

      Object.keys(datos).forEach(key => {
        formData.append(key,datos[key])
      })

      return new Promise((resolve, reject) => {
        if(datos.id){
          formData.append('_method','PUT')
          axios.post(`/api/widgets/${datos.id}`,formData,{headers}).then(({data}) => {
            if(data.result){
              commit('update',data.widget)
            }
            resolve(data)
          }).catch(e => reject(e))
        }else{
          axios.post(`/api/widgets`,formData,{headers}).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
        }
         
      })
    },

    eliminar({state,commit},widget_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/widgets/${widget_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

  }

}