
import { headers } from "@core/utils/utils"
export default {
  namespaced:true,
  state:() =>({
    
    reproductor:{
      id:null,
      nombre:'',
      marca:null,
      modelo:null,
      serie:null,
      imagen:null,
      clientes:[]
    },  

    reproductores:[]

  }),

  getters:{
    draft:(state) => clone(state.reproductor),

  },

  mutations:{

    clear(state){
      state.reproductor = {
        id:null,
        nombre:'',
        marca:null,
        modelo:null,
        serie:null,
        imagen:null,
        clientes:[]
      }
    },

    setReproductor(state,reproductor){
      if(typeof reproductor === 'object'){
        state.reproductor = reproductor
      }
    },


    setReproductores(state,reproductores){
      state.reproductores = reproductores
    }

  },


  actions:{

    fetch({commit},reproductor_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/reproductors/${reproductor_id}/fetch-data`).then(({data}) => {
          commit('setReproductor',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/reproductors/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    guardar({commit},datos){


      return new Promise((resolve, reject) => {

        let formData  = new FormData();
        
        Object.keys(datos).forEach(k => {
          formData.append(k,datos[k])
        })
        
        if(datos.id){
          formData.append('_method','PUT')
          axios.post(`/api/reproductors/${datos.id}`,formData,{headers}).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
        }else{
          axios.post(`/api/reproductors`,formData,{headers}).then(({data}) => {
            resolve(data)
          }).catch( e => reject(e))
        }
      })
    },

    eliminar({state,commit},reproductor_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/reproductors/${reproductor_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    cargarReproductoresLight({commit}){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/reproductors/get-light`).then(({data}) => {
          commit('setReproductores',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    }
  }

}