
import { headers } from "@core/utils/utils"
export default {
  namespaced:true,
  state:() =>({
    
    recarga:{
      id:null,
      monto:0,
      comprobante:null,
      paypal:false,
      paypal_payer_id:null,
      estatus:1,
      observacion:'',
      cliente_id:null,
      cliente:null,
      divisa_id:null,
      divisa:null,
      facturable:false,
      iva:0,
      total:0
    },  

    recargas:[]

  }),

  getters:{
    draft:(state) => clone(state.recarga),
    getEstatus:(state) => (estatus) => {
      let estatuses = ['En proceso','Procesada','Cancelada'];
      return estatuses[estatus - 1]
    },

    getVariantEstatus:(state) => (estatus) => {
      let variants = ['warning','success','danger'];
      return variants[estatus - 1]

    }

  },

  mutations:{

    clear(state){
      state.recarga = {
        id:null,
        monto:0,
        comprobante:null,
        paypal:false,
        paypal_payer_id:null,
        estatus:1,
        observacion:'',
        cliente_id:null,
        cliente:null,
        divisa_id:null,
        divisa:null,
        facturable:false,
        iva:0,
        total:0
      }
    },


    setSecargas(state,recargas){
      if(recargas){
        state.recargas = recargas
      }
    },


    setRecarga(state,recarga){
      if( recarga){
        state.recarga = recarga
      }
    }

  },


  actions:{

    fetch({commit},recarga_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/recargas/${recarga_id}/fetch-data`).then(({data}) => {
          commit('setRecarga',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/recargas/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    guardar({commit},datos){


      return new Promise((resolve, reject) => {

        let formData  = new FormData();
        
        Object.keys(datos).forEach(k => {
          formData.append(k,datos[k])
        })
        
        if(datos.id){
          formData.append('_method',PUT)
          axios.post(`/api/recargas/${datos.id}`,formData,{headers}).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
        }else{
          axios.post(`/api/recargas`,formData,{headers}).then(({data}) => {
            resolve(data)
          }).catch( e => reject(e))
        }
      })
    },

    eliminar({state,commit},recarga_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/recargas/${recarga_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    aprobar({state,commit},recarga_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/recargas/${recarga_id}/aprobar-pago`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },


    rechazar({state,commit},recarga_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/recargas/${recarga_id}/rechazar-pago`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    }


  }

}