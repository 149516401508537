import {headers} from '@core/utils/utils'

export default{
  namespaced:true,

  state:() => ({
    ticket:{
      id:null,
      estatus:0, // 0:pendiente, 1:atendiendo , 2:atendido, 3:cerrado
      fecha_atencion:null,
      area:1, // 1:Pagos y facturas, 2:Ventas, 3:Soporte
      usuario_id:null,
      usuario:null,
      mensajes:[],
    },

    mensaje:{
      id:null,
      mensaje:'',
      ticket_id:null,
      archivo:null,
      usuario_id:null,
      usuario:null
    },

    tickets:[],
    areasOptions:['Pagos y Facturas','Ventas','Soporte'],
  }),


  getters:{
    draft:(state) => clone(state.ticket),
    areasOptions: (state) => state.areasOptions.map((v,i) => ({text:v,value:i+1})),
    getVariantEstatus: (state) => (estatus_legend) => {
      switch(estatus_legend){
        case 'Pendiente':
          return 'warning'
        case 'Atendiendo':
          return 'info'
        case 'Atendido':
          return 'success'
        case 'Cerrado':
          return 'danger'
        default:
          return 'secondary'
      }
    }
  },

  mutations:{

    clear(state){
      state.ticket = {
        id:null,
        estatus:0, // 0:pendiente, 1:atendiendo , 2:atendido, 3:cerrado
        fecha_atencion:null,
        area:1, // 1:administracion, 2:Cobranza, 3:Soporte
        usuario_id:null,
        usuario:null,
        mensajes:[],
      }
    },

    clearMensaje(state) {
      state.mensaje = {
        id:null,
        mensaje:'',
        ticket_id:null,
        archivo:null,
        usuario_id:null,
        usuario:null
      }
    },

    setTicket(state,ticket){
      if(ticket){
        state.ticket = ticket
      }
    },


    setTickets:(state,tickets) => {
      state.tickets = tickets
    },

    pushMensaje(state,mensaje){
      if(state.ticket.id == mensaje.ticket_id){
        state.ticket.mensajes.push(mensaje)
        // reordenar mensajes por fecha, el mas nuevo primero
        state.ticket.mensajes = state.ticket.mensajes.sort((a,b) => {
          if(a.fecha_creacion < b.fecha_creacion){
            return 1
          }else if(a.fecha_creacion > b.fecha_creacion){
            return -1
          }else{
            return 0
          }
        })

       
      }
    }
  },  

  actions:{

    fetch({commit},ticket_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/tickets/${ticket_id}/fetch-data`).then(({data}) => {
          commit('setTicket',data)
          resolve(data)
        }).catch(e => reject(e))
      })

    },

    fetchData({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post('/api/tickets/fetch-data',datos).then(({data}) => {
          commit('setTickets',data.tickets);
          resolve(data);
        }).catch(e => {
          reject(e)
        });

      })
    },

    crear({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post('/api/tickets',datos).then(({data}) => {
          if(data.result){
            commit('setTicket',data.ticket)
          }
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    updateEstatus({commit},{ticket_id,estatus}){
      return new Promise((resolve, reject) => {
        axios.put(`/api/tickets/${ticket_id}/update-estatus`,{estatus}).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    enviarMensaje({state,commit},mensaje){
      
      const formData = new FormData();

      Object.keys(mensaje).forEach(key => {
        formData.append(key,mensaje[key])
      });

      return new Promise((resolve, reject) => {

        axios.post(`/api/tickets/${state.ticket.id}/push-mensaje`,formData,{headers}).then(({data}) => {
          if(data.result){
              commit('pushMensaje',data.mensaje)
          }
          resolve(data)
        }).catch(e => reject(e));

      });

    },

    cargarCantidadTickets({commit}){
      return new Promise((resolve, reject) => {
        axios.get('/api/tickets/cantidad-tickets-sin-atender').then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    eliminar({commit},ticket_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/tickets/${ticket_id}`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    atender({commit},ticket_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/tickets/${ticket_id}/atender`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    }

  }
}