import { headers } from "@core/utils/utils"
export default {
  namespaced:true,
  state:() =>({
    
    area:{
      id:null,
      nombre:'',
      cliente_id:null,
      cliente:null
    },

    areas:[]

  }),

  getters:{
    draft:(state) => clone(state.area),
  },



  mutations:{

    clear(state){
      state.area = {
        id:null,
        nombre:'',
        cliente_id:null,
        cliente:null
      }
    },


    setAreas(state,areas){
      if(areas){
        state.areas = areas
      }
    },


    setArea(state,area){
      if( area){
        state.area = area
      }
    }


  },


  actions:{

    fetch({commit},area_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/areas/${area_id}/fetch-data`).then(({data}) => {
          commit('setArea',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/areas/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    guardar({commit},datos){


      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/areas/${datos.id}`,datos).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
        }else{
          axios.post(`/api/areas`,datos).then(({data}) => {
            resolve(data)
          }).catch( e => reject(e))
        }
      })
    },

    eliminar({state,commit},area_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/areas/${area_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    getListSelect({state,commit},cliente_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/areas/list-select/cliente/${cliente_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    }
  }

}