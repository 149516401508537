import { headers } from "@core/utils/utils"
export default {
  namespaced:true,
  state:() =>({
    
    categoria:{
      id:null,
      nombre:null,
      descripcion:'',
      icono:null,
      categoria_id:null,
      categoria:null,
      sub_categorias:[],
      
    },

    categorias:[],

  }),

  getters:{
    draft:(state) => clone(state.categoria),
  },



  mutations:{

    clear(state){
      state.categoria = {
        id:null,
        nombre:null,
        descripcion:'',
        icono:null,
      }
    },


    setCategorias(state,categorias){
      if(categorias){
        state.categorias = categorias
      }
    },


    setCategoria(state,categoria){
      if( categoria){
        state.categoria = categoria
      }
    },
    update(state,categoria){
      if(categoria){
        state.categoria = categoria
      }
    },

   

  },


  actions:{ 

    subCategorias({commit},categoria_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/categorias/${categoria_id}/sub-categorias`).then(({data}) => {
          commit('setCategorias',data)
          resolve(data)
        }).catch(e =>  reject(e))
      })
    },


    getLight({commit}){
      return new Promise((resolve, reject) => {
        axios.get(`/api/categorias/listado/get-light`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    getLightTree({commit}){
      return new Promise((resolve, reject) => {
        axios.get(`/api/categorias/listado/get-light-tree`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },


    fetch({commit},categoria_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/categorias/${categoria_id}/fetch-data`).then(({data}) => {
          commit('setCategoria',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/categorias/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    guardar({commit,dispatch},datos){

      let formData = new FormData();
      Object.keys(datos).forEach(k => {
        formData.append(k,datos[k])
      });

      return new Promise((resolve, reject) => {

        if(datos.id){
          formData.append('_method','PUT');
          axios.post(`/api/categorias/${datos.id}`,formData,{headers}).then(({data}) => resolve(data)).catch(e => reject(e))
        }else{
          axios.post(`/api/categorias`,formData,{headers}).then(({data}) => resolve(data)).catch(e => reject(e))
        }

      })
    },

    eliminar({state,commi},categoria_id){
        return new Promise((resolve, reject) => {
          axios.delete(`/api/categorias/${categoria_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
        })
    }

    

  }
}